import { Button as ButtonInternal } from '@/components/atoms/Button';
import { DeleteConfirmationDialog } from '@/components/atoms/Dialog';
import { TextInput } from '@/components/atoms/TextInput';
import PhoneMask from '@/components/atoms/TextInput/PhoneMask';
import TextWithShowMore from '@/components/atoms/Utils/TextWithShowMore';
import { alertMessages } from '@/config';
import { useAppSelector } from '@/hooks';
import {
  clearCreatedLead,
  clearUpdatedLead,
  createLead,
  getClientStaffMembers,
  removeLeadContact,
  updateLead,
} from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useLeadCreateStyle } from '@/static/stylesheets/organisms';
import { CustomerProfileParams, UserParams } from '@/types';
import { convertToPayloadParam } from '@/utils/convertToPayloadParam';
import { validatePhoneNumber } from '@/utils/validatePhoneNumber';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddBox, DeleteForever, Star } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';

interface CreateLeadsProps {
  open: boolean;
  mode: 'create' | 'edit';
  editData?: CustomerProfileParams;
  reloadList?(): void;
  onClose(): void;
  title?: string;
  onSuccess?(customerData: CustomerProfileParams): void;
}

const schema = yup
  .object({
    assigned_to: yup.string().test({
      name: 'assigned_to',
      message: 'Assigned To is required.',
      test: (value: any) => value !== '--empty--',
    }),
    customer_type: yup.string().test({
      name: 'customer_type',
      message: 'Account Type is required.',
      test: (value: any) => value !== '--empty--',
    }),
    first_name: yup.string().when('customer_type', ([customer_type], schema) => {
      return customer_type === 'Business'
        ? schema.required('Must provide company name')
        : schema.notRequired().optional();
    }),
    last_name: yup.string().optional(),
    street: yup.string().required('Street is required!'),
    apartment: yup.string().optional(),
    city: yup.string().required('City is required!'),
    zip: yup.string().required('ZIP is required!'),
    add_to_lead_campaign_drip_workflow: yup.boolean(),
    add_to_client_campaign_drip_workflow: yup.boolean(),
    customer_contacts: yup
      .array(
        yup.object({
          id: yup.string().optional(),
          first_name: yup.string().required('First name is required!'),
          last_name: yup.string().required('Last name is required!'),
          phone: yup.string().nullable().optional(),
          email: yup.string().email('Email is not valid!').required('Email is required!'),
          is_primary: yup.boolean().required(),
          notes: yup.string().optional(),
          types: yup.array().optional(),
        }),
      )
      .required(),
  })
  .required();

interface IFormData {
  assigned_to: string;
  customer_type: string;
  first_name: string;
  last_name: string;
  street: string;
  apartment: string;
  city: string;
  zip: string;
  add_to_lead_campaign_drip_workflow: boolean;
  add_to_client_campaign_drip_workflow: boolean;
  customer_contacts: {
    id: string;
    first_name: string;
    last_name: string;
    phone?: string | null;
    email: string;
    is_primary: boolean;
    notes?: string;
    types?: string[];
  }[];
}

const initialFormData: IFormData = {
  assigned_to: '--empty--',
  customer_type: '--empty--',
  first_name: '',
  last_name: '',
  street: '',
  apartment: '',
  city: '',
  zip: '',
  add_to_lead_campaign_drip_workflow: false,
  add_to_client_campaign_drip_workflow: false,
  customer_contacts: [
    {
      id: '',
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      is_primary: false,
      notes: '',
      types: [],
    },
  ],
};

const numberWithDot = (x: string) => {
  return x ? x.replace(/(\d{3})(\d{3})(\d{4})/, '$1.$2.$3') : x;
};

const CreateLead: React.FC<CreateLeadsProps> = (props) => {
  const { open, onClose, mode, editData, reloadList, title, onSuccess } = props;
  const [removeIndex, setRemoveIndex] = useState<number>(-1);
  const [isDeleteLead, setIsDeleteLead] = useState<boolean>(false);
  const staffMemberList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_CLIENT_STAFF_MEMBERS];
  const staffMembers: UserParams[] = staffMemberList?.data?.data ?? [];

  const getStaffMemberList = () =>
    getClientStaffMembers({
      limit: 'all',
      page: 1,
    });

  const createdLead = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_LEAD];
  const updatedLead = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_LEAD];

  const classes = useLeadCreateStyle();
  const editMode: boolean = mode === 'edit' && editData !== undefined && editData?.id !== '';
  const isClient = title?.toLocaleLowerCase() === 'client'
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialFormData,
    resolver: yupResolver(schema),
  });

  const { fields, replace, append, remove } = useFieldArray({
    control,
    name: 'customer_contacts',
  });

  const { customer_type, customer_contacts } = watch();
  const onSubmit = handleSubmit((data) => {
    if (!data.customer_contacts.some((field) => field.is_primary)) {
      toast.error('At least 1 contact has a default!');
      return;
    }
    const primaryContact = data?.customer_contacts?.find((item) => item.is_primary);
    if (editMode) {
      updateLead({
        id: editData?.id,
        ...data,
        ...(isClient ? { is_client: true } : { is_client: false }),
        add_to_client_campaign_drip_workflow: undefined,
        add_to_lead_campaign_drip_workflow: undefined,
        first_name: data?.customer_type !== 'Business' ? primaryContact?.first_name : data?.first_name,
        last_name: data?.customer_type !== 'Business' ? primaryContact?.last_name : '',
        customer_contacts: (data?.customer_contacts ?? [])?.map((_) => {
          const updatedContacts = {
            ..._,
            notes: convertToPayloadParam(_.notes, null),
            phone: convertToPayloadParam(_.phone?.replaceAll(/\./g, ''), null),
          };
          return updatedContacts;
        }),
      });
    } else {
      createLead({
        ...data,
        ...(isClient ? { is_client: true } : { is_client: false }),
        add_to_client_campaign_drip_workflow: isClient ? data?.add_to_client_campaign_drip_workflow : undefined,
        add_to_lead_campaign_drip_workflow: isClient ? undefined : data?.add_to_lead_campaign_drip_workflow,
        first_name: data?.customer_type !== 'Business' ? primaryContact?.first_name : data?.first_name,
        last_name: data?.customer_type !== 'Business' ? primaryContact?.last_name : '',
        customer_contacts: (data?.customer_contacts ?? [])?.map((_) => {
          const contacts = {
            ..._,
            notes: convertToPayloadParam(_.notes, null),
            phone: convertToPayloadParam(_.phone?.replaceAll(/\./g, ''), null),
          };
          return contacts;
        }),
      });
    }
  });

  // tabs
  const [contactPersonTab, setContactPersonTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setContactPersonTab(newValue);
  };
  const addNewContact = () => {
    append({
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      is_primary: false,
      notes: '',
    });
    setContactPersonTab(fields.length);
  };

  const removeContact = async (index: number) => {
    setRemoveIndex(-1);
    const customerField: any = fields[index];
    const removeCustomer = editData?.customer_contacts?.find((_) => _?.id === customerField?.customer_id);
    if (removeCustomer?.id) {
      await removeLeadContact(removeCustomer?.id);
    }

    remove(index);
    setContactPersonTab(index ? index - 1 : index);
    setIsDeleteLead(true);
  };

  useEffect(() => {
    getStaffMemberList();
  }, [open]);

  useEffect(() => {
    if (mode === 'edit') {
      setValue('assigned_to', editData?.assigned_to!);
      setValue('customer_type', editData?.customer_type!);
      setValue('first_name', editData?.customer_type === 'Business' ? editData?.first_name : '');
      setValue('last_name', editData?.customer_type === 'Business' ? editData?.last_name || '' : '');
      setValue('street', editData?.street || '');
      setValue('apartment', editData?.apartment || '');
      setValue('city', editData?.city || '');
      setValue('zip', (editData?.zip)?.toString() || '');
      
      if (editData?.customer_contacts?.length) {
        replace(
          editData?.customer_contacts.map((_: any) => {
            return {
              customer_id: _.id,
              id: _.id,
              first_name: _.contact_first_name,
              last_name: _.contact_last_name,
              phone: _.contact_phone,
              email: _.contact_email,
              is_primary: _.contact_is_primary,
              notes: _.contact_notes || undefined,
              types: (_.contact_types || [])?.map((_: any) => _.contact_type_name),
            };
          }),
        );
      }
    }
  }, [editData]);

  useEffect(() => {
    if (editMode) {
      if (updatedLead && updatedLead?.success) {
        clearUpdatedLead();
        reset(initialFormData);
        title?.toLowerCase() === 'client'
          ? toast.success(alertMessages.clients.clientUpdateSuccess)
          : toast.success(alertMessages.leads.leadUpdateSuccess);
        customOnClose();
      }

      if (updatedLead && updatedLead?.error) {
        clearUpdatedLead();
        toast.error(<TextWithShowMore text={updatedLead?.error?.data?.message} />);
      }
    } else {
      if (createdLead && createdLead?.success) {
        clearCreatedLead();
        if (onSuccess && title?.toLowerCase() === 'client') {
          onSuccess(createdLead?.data);
        }
        title?.toLowerCase() === 'client'
          ? toast.success(alertMessages.clients.clientCreateSuccess)
          : toast.success(alertMessages.leads.leadCreateSuccess);
        customOnClose();
      }

      if (createdLead && createdLead?.error) {
        clearCreatedLead();
        toast.error(<TextWithShowMore text={updatedLead?.error?.data?.message} />);
      }
    }
  }, [updatedLead, createdLead, editMode]);

  const customOnClose = () => {
    reset(initialFormData);
    if (isDeleteLead) {
      reloadList && reloadList();
      onClose();
    }
    onClose();
  };
  return (
    <>
      <Dialog
        className={classes.root}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        maxWidth={'lg'}
        fullWidth={true}
        onClose={customOnClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle className={classes.title} id='responsive-dialog-title'>
          {`${editMode ? 'Edit' : 'Add New'} ${title}`}
        </DialogTitle>
        <DialogContent className={classes.body}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={5} lg={4}>
              <Controller
                name='assigned_to'
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <TextInput
                    {...field}
                    variant='standard'
                    defaultValue={'--empty--'}
                    options={[
                      {
                        title: 'Assigned To',
                        value: '--empty--',
                        disabled: true,
                      },
                      ...(staffMembers && staffMembers?.length > 0
                        ? staffMembers?.map((item) => ({
                            title: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`,
                            value: item?.id,
                          }))
                        : []),
                    ]}
                    hiddenLabel
                    select={true}
                    value={value}
                    fullWidth={true}
                    onChange={({ target: { value } }) => {
                      onChange(value);
                    }}
                    error={!!errors?.assigned_to?.message}
                    errorText={errors?.assigned_to?.message}
                  />
                )}
              />
              <Controller
                name='customer_type'
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <TextInput
                    {...field}
                    variant='standard'
                    defaultValue={'--empty--'}
                    options={[
                      {
                        title: 'Account Type',
                        value: '--empty--',
                        disabled: true,
                      },
                      {
                        title: 'Company',
                        value: 'Business',
                      },
                      {
                        title: 'Residential',
                        value: 'Residential',
                      },
                    ]}
                    hiddenLabel
                    value={value}
                    select={true}
                    fullWidth={true}
                    placeholder='Company Name'
                    onChange={({ target: { value } }) => {
                      onChange(value);
                    }}
                    error={!!errors?.customer_type?.message}
                    errorText={errors?.customer_type?.message}
                  />
                )}
              />

              <Controller
                name='first_name'
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <TextInput
                    {...field}
                    disabled={customer_type?.toLocaleLowerCase() === 'residential' ? true : false}
                    hiddenLabel
                    value={value}
                    fullWidth={true}
                    placeholder='Company Name'
                    onChange={({ target: { value } }) => {
                      onChange(value);
                    }}
                    error={!!errors?.first_name?.message}
                    errorText={errors?.first_name?.message || ''}
                  />
                )}
              />

              <Controller
                name='street'
                control={control}
                defaultValue=''
                render={({ field: { value, onChange, ...field } }) => (
                  <TextInput
                    {...field}
                    hiddenLabel
                    value={value}
                    fullWidth={true}
                    placeholder='Street Address'
                    onChange={({ target: { value } }) => {
                      onChange(value);
                    }}
                    error={!!errors?.street?.message}
                    errorText={errors?.street?.message}
                  />
                )}
              />

              <Controller
                name='apartment'
                control={control}
                defaultValue=''
                render={({ field: { value, onChange, ...field } }) => (
                  <TextInput
                    {...field}
                    hiddenLabel
                    value={value}
                    fullWidth={true}
                    placeholder='Suite/Apt #'
                    onChange={({ target: { value } }) => {
                      onChange(value);
                    }}
                    error={!!errors?.apartment?.message}
                    errorText={errors?.apartment?.message}
                  />
                )}
              />

              <Controller
                name='city'
                control={control}
                defaultValue=''
                render={({ field: { value, onChange, ...field } }) => (
                  <TextInput
                    {...field}
                    hiddenLabel
                    fullWidth={true}
                    value={value}
                    placeholder='City'
                    onChange={({ target: { value } }) => {
                      onChange(value);
                    }}
                    error={!!errors?.city?.message}
                    errorText={errors?.city?.message}
                  />
                )}
              />

              <Controller
                name='zip'
                control={control}
                defaultValue=''
                render={({ field: { value, onChange, ...field } }) => (
                  <TextInput
                    {...field}
                    hiddenLabel
                    fullWidth={true}
                    value={value}
                    placeholder='Zip Code'
                    onChange={({ target: { value } }) => {
                      onChange(value);
                    }}
                    error={!!errors?.zip?.message}
                    errorText={errors?.zip?.message}
                  />
                )}
              />
              {!editMode && <Controller
                name={title?.toLowerCase() === 'client' ? `add_to_client_campaign_drip_workflow` : `add_to_lead_campaign_drip_workflow`}
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <FormControlLabel
                    {...field}
                    checked={value}
                    className={classes.label}
                    control={<Checkbox color={'primary'} />}
                    label={title?.toLowerCase() === 'client' ? 'Add to Client Campaign Drip Workflow' : 'Add to Lead Campaign Drip Workflow'}
                    onChange={({ target: { checked } }: any) => {
                      onChange(checked);
                    }}
                  />
                )}
              />}
            </Grid>
            <Grid item className='tabContainer' xs={12} sm={12} md={7} lg={8}>
              <Box
                className={classes.contactPersons}
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                }}
              >
                <Tabs
                  className='contactPersonTabs'
                  orientation='vertical'
                  variant='scrollable'
                  value={contactPersonTab}
                  onChange={handleChange}
                  aria-label='Vertical tabs example'
                  sx={{
                    width: 290,
                    borderRight: 1,
                    backgroundColor: 'transparent',
                    borderColor: 'divider',
                  }}
                >
                  {fields.map((field, index: number) => (
                    <Tab
                      key={field.id}
                      label={
                        <p
                          style={{
                            margin: 0,
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                          }}
                        >
                          {customer_contacts[index]?.is_primary && <Star style={{ height: '18px' }} />}{' '}
                          {!customer_contacts[index]?.first_name && !customer_contacts[index]?.last_name
                            ? `New contact ${index + 1}`
                            : `${customer_contacts[index]?.first_name || ''} ${
                                customer_contacts[index]?.last_name || ''
                              }`}
                        </p>
                      }
                      {...a11yProps(index)}
                    />
                  ))}
                  <Button onClick={addNewContact} className='addNewContactBtn'>
                    <AddBox style={{ height: '18px' }} /> Add new contact
                  </Button>
                </Tabs>
                {fields.map((inputField, index: number) => (
                  <TabPanel key={inputField.id} value={contactPersonTab} index={index}>
                    <Controller
                      name={`customer_contacts.${index}.first_name`}
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <TextInput
                          {...field}
                          hiddenLabel
                          fullWidth={true}
                          placeholder='First Name'
                          onChange={({ target: { value } }) => {
                            onChange(value);
                          }}
                          value={value}
                          error={errors?.customer_contacts && !!errors?.customer_contacts[index]?.first_name?.message}
                          errorText={errors?.customer_contacts && errors?.customer_contacts[index]?.first_name?.message}
                        />
                      )}
                    />
                    <Controller
                      name={`customer_contacts.${index}.last_name`}
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <TextInput
                          {...field}
                          hiddenLabel
                          fullWidth={true}
                          placeholder='Last Name'
                          onChange={({ target: { value } }) => {
                            onChange(value);
                          }}
                          value={value}
                          error={errors?.customer_contacts && !!errors?.customer_contacts[index]?.last_name?.message}
                          errorText={errors?.customer_contacts && errors?.customer_contacts[index]?.last_name?.message}
                        />
                      )}
                    />
                    <Controller
                      name={`customer_contacts.${index}.phone`}
                      control={control}
                      render={({ field }) => (
                        <PhoneMask
                          {...field}
                          hiddenLabel
                          fullWidth={true}
                          placeholder='Phone Number'
                          error={errors?.customer_contacts && !!errors?.customer_contacts[index]?.phone?.message}
                          errorText={errors?.customer_contacts && errors?.customer_contacts[index]?.phone?.message}
                        />
                      )}
                    />
                    <Controller
                      name={`customer_contacts.${index}.email`}
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <TextInput
                          {...field}
                          hiddenLabel
                          fullWidth={true}
                          placeholder='Email'
                          value={value}
                          onChange={({ target: { value } }) => {
                            onChange(value);
                          }}
                          error={errors?.customer_contacts && !!errors?.customer_contacts[index]?.email?.message}
                          errorText={errors?.customer_contacts && errors?.customer_contacts[index]?.email?.message}
                        />
                      )}
                    />
                    {/* in future required this input then used this one for contact type */}
                    {/* <Controller
                      name={`customer_contacts.${index}.types`}
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <MultiSelect
                          options={[
                            {
                              label: 'Home',
                              value: 'Home',
                              sign: 'green',
                            },
                            {
                              label: 'Billing',
                              value: 'Billing',
                              sign: 'green',
                            },
                          ]}
                          type='indicatorBox'
                          value={value || '__empty__'}
                          onChange={(e) => onChange(e)}
                          fullWidth={true}
                          multiple={true}
                          // error={}
                          // errorText={}
                        />
                      )}
                    /> */}
                    <p className='contactTypeInput'>{(fields[index].types || []).join(', ') || 'Contact type'}</p>

                    <Controller
                      name={`customer_contacts.${index}.notes`}
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <TextareaAutosize
                          {...field}
                          className='contactNotes'
                          style={{ height: '80px' }}
                          value={value}
                          placeholder='Contact Notes...'
                          onChange={({ target: { value } }) => {
                            onChange(value);
                          }}
                        />
                      )}
                    />
                    <div className='tabPanelAction'>
                      <Controller
                        name={`customer_contacts.${index}.is_primary`}
                        control={control}
                        render={({ field: { value, onChange, ...field } }) => (
                          <FormControlLabel
                            {...field}
                            checked={value}
                            control={<Checkbox color={'primary'} />}
                            label='Make Primary Contact'
                            disabled={fields.some((field, i) => i !== index && field.is_primary) ? true : false}
                            onChange={({ target: { checked } }: any) => {
                              const uFeilds = customer_contacts.map((_, i: number) => {
                                return {
                                  ..._,
                                  is_primary: i === index ? checked : false,
                                };
                              });

                              replace(uFeilds);
                            }}
                          />
                        )}
                      />

                      {!customer_contacts[index]?.is_primary && (fields ?? []).length > 1 && (
                        <IconButton
                          color='error'
                          aria-label='delete'
                          size='large'
                          type='button'
                          className='deleteContactBtn'
                          onClick={() => setRemoveIndex(index)}
                        >
                          <DeleteForever />
                        </IconButton>
                      )}
                    </div>
                  </TabPanel>
                ))}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <ButtonInternal onClick={customOnClose} color={'error'} variant={'contained'} title={'CANCEL'} />
          <ButtonInternal
            onClick={onSubmit}
            color={'success'}
            variant={'contained'}
            title={
              <span style={{ textTransform: 'uppercase' }}>
                {editMode ? 'SAVE' : 'CREATE'} {title}
              </span>
            }
            loading={createdLead?.loading || updatedLead?.loading}
          />
        </DialogActions>
      </Dialog>
      <DeleteConfirmationDialog
        title={
          <Box textAlign={'center'}>
            Are you sure you want to delete this contact? <br /> You will not be able to undo this action.
          </Box>
        }
        rightButtonText={'Yes, delete contact'}
        open={removeIndex > -1 ? true : false}
        onClose={() => setRemoveIndex(-1)}
        onConfirm={() => removeContact(removeIndex)}
      />
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className='tabPanel'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className='tabPanelContent'>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export { CreateLead };
