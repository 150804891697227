import React, { useEffect, useState } from 'react';
import { DataTable } from '@/components/organisms/DataTable';
import { ActionButton } from '@/components/atoms/Button';
import { Button } from '@/components/atoms/Button';
import { StaffMemberForm } from '@/components/molecules/Settings/StaffMember';
import { ResetPasswordForm } from '@/components/molecules/Settings/StaffMember';
import { clearDeletedUser, deleteUser, getUsers, openConfirmationDialog } from '@/redux/actions';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { UserParams } from '@/types';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';

const StaffMember = () => {
  const userList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_USERS];
  const users = userList?.data?.data ?? [];
  const totalPages = userList?.data?.last_page ?? 0;

  const deletedUser = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_USER];

  const [limit, setLimit] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const [formOpen, setFormOpen] = useState(false);
  const [openResetPasswordForm, setOpenResetPasswordForm] = useState(false);
  const [editFormData, setEditFormData] = useState<UserParams | null>(null);
  const [resetFormUserId, setResetFormUserId] = useState<UserParams['id'] | null>(null);

  const openFormDialog = (mode: 'add' | 'edit', formData: UserParams | null) => {
    setFormOpen(true);
    setEditFormData(formData);
  };
  const openPasswordResetForm = (id: UserParams['id']) => {
    setOpenResetPasswordForm(true);
    setResetFormUserId(id);
  };
  const handleCloseFormDialog = () => {
    setFormOpen(false);
    setTimeout(() => {
      setEditFormData(null);
    }, 100);
  };

  const handleClosePasswordResetForm = () => {
    setOpenResetPasswordForm(false);
    setResetFormUserId(null);
  };

  const fetchUserList = () => {
    getUsers({
      limit,
      page: currentPage,
    });
  };

  const deleteStaffUser = (id: UserParams['id']) => {
    deleteUser(id);
  };

  useEffect(() => {
    if (deletedUser?.success) {
      toast.success(alertMessages.generalSettings.staffMembers.deleteSuccess);
      fetchUserList();
      clearDeletedUser();
    }

    if (deletedUser?.error) {
      toast.error(alertMessages.generalSettings.staffMembers.deleteError);
      clearDeletedUser();
    }
  }, [deletedUser]);

  useEffect(() => {
    fetchUserList();
  }, [currentPage, limit]);

  return (
    <>
      <div style={{ textAlign: 'center', marginTop: 40, padding: 10 }}>
        <div style={{ width: '100%', display: 'inline-block' }}>
          <DataTable
            heads={[
              { label: 'First Name', align: 'left' },
              { label: 'Last Name', align: 'left' },
              { label: 'Email address', align: 'left' },
              { label: 'Cell Phone', align: 'left' },
              { label: 'Title', align: 'left' },
              { label: 'Actions', align: 'center' },
            ]}
            rows={[
              ...users?.map((user: UserParams) => [
                { label: user?.first_name ?? '', align: 'left' },
                { label: user?.last_name ?? '', align: 'center' },
                { label: user?.email ?? '', align: 'center' },
                { label: user?.phone ?? '', align: 'center' },
                { label: user?.title ?? '', align: 'center' },
                {
                  label: (
                    <div className={'action-icon'}>
                      <ActionButton onClick={() => openFormDialog('edit', user)} icon={'edit'} />
                      <ActionButton onClick={() => openPasswordResetForm(user?.id)} icon={'lock'} />
                      <ActionButton
                        icon={'delete'}
                        onClick={() =>
                          openConfirmationDialog({
                            title: 'Are you sure?',
                            message: 'Are you sure to delete this staff?',
                            type: 'error',
                            textCenter: 'center',
                            onClickConfirm() {
                              deleteStaffUser(user?.id);
                            },
                          })
                        }
                      />
                    </div>
                  ),
                  align: 'center',
                },
              ]),
            ]}
            hasPagination={true}
            paginationProps={{
              count: totalPages,
              currentPage,
              onChange(value) {
                setCurrentPage(value);
              },
            }}
          />
        </div>
        <div style={{ padding: '10px 10px 25px 10px' }}>
          <Button
            onClick={() => openFormDialog('add', null)}
            color={'primary'}
            variant={'contained'}
            title={'Add Staff Member'}
            fullWidth={true}
            size={'small'}
          />
        </div>
        <StaffMemberForm
          open={formOpen}
          formData={editFormData}
          onClose={handleCloseFormDialog}
          onReload={fetchUserList}
        />
        <ResetPasswordForm
          userId={resetFormUserId ?? ''}
          open={openResetPasswordForm}
          onClose={handleClosePasswordResetForm}
        />
      </div>
    </>
  );
};

export { StaffMember };
