import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useAppSelector } from '@/hooks';
import { selectUiState } from '@/redux/reducers/uiSlice';
import { timezone } from '@/utils/timeZone';

import {
  Activity,
  Files,
  RightHeader,
  LeftHeader,
  ProposalInfo,
  QuickNotes,
  Source,
} from '@/components/organisms/Clients';
import { useClientStyles } from '@/static/stylesheets/organisms';
import { apiClient, endpoints } from '@/api';

interface SingleLeadProps {
  optionsExpanded?: boolean;
  isDeactivatedClient?: boolean;
  data?: any;
  reloadList?(): void;
}

export type ToggleSaveButtonEnabled = (enable?: boolean) => void;

const SingleClient: React.FC<SingleLeadProps> = (props) => {
  const classes = useClientStyles();
  const { data, reloadList } = props;
  const leadsExpanded = useAppSelector(selectUiState).leadsExpanded;
  const [optionsExpanded, setOptionsExpanded] = useState<boolean>(false);
  const [filesMenuOpen, setFilesMenuOpen] = useState<boolean>(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);
  const [notes, setNotes] = useState<any[]>([]);
  const [activity, setActivity] = useState<any[]>([]);
  //console.log(notes,'notes')
  const toggleSaveEnabled: ToggleSaveButtonEnabled = (toggle: boolean | undefined = undefined) => {
    setIsSaveEnabled(toggle ?? !isSaveEnabled);
  };

  const onToggleOptions = () => {
    setOptionsExpanded(!optionsExpanded);
  };
  const onToggleFilesMenu = () => {
    setFilesMenuOpen(!filesMenuOpen);
  };

  const getFiles = () => {
    if (data?.id) {
      apiClient
        .get({
          url: endpoints.private.getClientFileDetails(data?.id),
          method: 'get',
          useBearerToken: true,
        })
        .then(({ data }) => {
          if (data) {
            setFiles(() => [...data]);
          }
        })
        .catch(() => {
          alert();
        });
    }
  };
  const getNotes = () => {
    if (data?.id) {
      apiClient
        .get({
          url: endpoints.private.getLeadQuickNotes(data?.id),
          method: 'get',
          useBearerToken: true,
        })
        .then(({ data }) => {
          if (data?.data) {
            setNotes(() => [...data?.data]);
          }
        })
        .catch((e) => {
          alert(e);
        });
    }
  };

  const getActivity = () => {
    if (data?.id) {
      apiClient
        .get({
          url: endpoints.private.getLeadActivityLogs({
            id: data?.id,
            timezone: timezone,
            page: 1,
            limit: 25,
          }),
          method: 'get',
          useBearerToken: true,
        })
        .then(({ data }) => {
          if (data?.data) {
            setActivity(() => [...data?.data]);
          }
        })
        .catch((e) => {});
    }
  };
  useEffect(() => {
    setOptionsExpanded(leadsExpanded);
  }, [leadsExpanded]);

  useEffect(() => {
    if (optionsExpanded && filesMenuOpen) {
      getFiles();
    }
  }, [filesMenuOpen, optionsExpanded]);

  return (
    <>
      <Grid
        container
        spacing={2}
        className={optionsExpanded ? `${classes.root} ${classes.activeBody}` : `${classes.root}`}
      >
        <Grid item sm={12} md={6} lg={7} className={classes.leftColumn}>
          <LeftHeader
            isDeactivatedClient={props?.isDeactivatedClient}
            customerData={data?.customer}
            LeadsData={data}
            toggleSaveEnabled={toggleSaveEnabled}
            reloadList={reloadList}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={5} className={classes.rightColumn}>
          <RightHeader
            clientData={data}
            expandActivity={optionsExpanded}
            filesMenuOpen={filesMenuOpen}
            onToggleOptions={onToggleOptions}
            onToggleFilesMenu={onToggleFilesMenu}
            isDeactivatedClient={props?.isDeactivatedClient}
            isSaveEnabled={isSaveEnabled}
            reloadActivityLogList={getActivity}
          />
        </Grid>

        <Grid className={classes.trackingContainer} item sm={12} md={12} lg={12}>
          <div className={optionsExpanded ? `${classes.show}` : `${classes.hide}`}>
            {optionsExpanded && !filesMenuOpen && (
              <Grid container spacing={2} className={classes.expandContainer}>
                <Grid item sm={6} md={3} lg={3}>
                  <Source
                    isDeactivatedClient={props?.isDeactivatedClient}
                    toggleSaveEnabled={toggleSaveEnabled}
                    clientData={data}
                  />
                </Grid>
                <Grid item sm={6} md={3} lg={3}>
                  <ProposalInfo
                    isDeactivatedClient={props?.isDeactivatedClient}
                    toggleSaveEnabled={toggleSaveEnabled}
                    clientData={data}
                  />
                </Grid>
                <Grid item sm={6} md={3} lg={3}>
                  {optionsExpanded && (
                    <QuickNotes
                      isDeactivatedClient={props?.isDeactivatedClient}
                      toggleSaveEnabled={toggleSaveEnabled}
                      leadsNotes={notes}
                      leadsId={data?.id}
                      onReload={getNotes}
                    />
                  )}
                </Grid>
                <Grid item sm={6} md={3} lg={3}>
                  {optionsExpanded && <Activity leadsActivity={activity} leadsId={data?.id} onReload={getActivity} />}
                </Grid>
              </Grid>
            )}

            {optionsExpanded && filesMenuOpen && (
              <Files
                isDeactivatedClient={props?.isDeactivatedClient}
                leadsFiles={files}
                leadsId={data?.id}
                onReload={getFiles}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export { SingleClient };
