import { TextInput } from '@/components/atoms/TextInput';
import { Filter } from '@/components/molecules/Filter/Filter';
import { CreateJob } from '@/components/molecules/Jobs';
import { CreateLead } from '@/components/molecules/Leads/CreateLead';
import { CreateTaskDialog } from '@/components/molecules/Tasks';
import { Calculator } from '@/components/organisms/Calculator';
import { CreateEquipmentOrders } from '@/components/organisms/EquipmentOrders';
import { useAppSelector, useInput } from '@/hooks';
import { useJobListType } from '@/hooks/custom';
import {
  expandLeads,
  getClients,
  getEquipmentOrdersCancelled,
  getEquipmentOrdersCompleted,
  getEquipmentOrdersOpen,
  getJobs,
  getLeads,
  getTasks,
  setGlobalSearch,
  setJobListType,
  setReportGlobalReload,
  toggleDeactivatedClients,
  toggleLostLeads,
} from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { UiState, selectUiState } from '@/redux/reducers/uiSlice';
import { routePaths } from '@/routes';
import Logo from '@/static/images/logo.png';
import { useHeaderStyles } from '@/static/stylesheets/molecules/headerStyles';
import { ThemeState, withAppTheme } from '@/theme';
import { FeatureType } from '@/types';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FilterListIcon from '@mui/icons-material/FilterList';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import LoopIcon from '@mui/icons-material/Loop';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateClient } from '@/components/molecules/Clients';

interface HeaderProps {
  hideHeaderProfile?: boolean;
  hideHeaderRightSide?: boolean;
  featureType?: FeatureType;

  onChangeLeadType?(): void;

  turnOnReloadActive?(): void;
}

interface ThemeProps {
  setAppTheme: ThemeState['setAppTheme'];
  appTheme: ThemeState['appTheme'];
}

const Header: React.FC<HeaderProps & ThemeProps> = (props) => {
  const classes = useHeaderStyles();
  const navigate = useNavigate();
  const _navigate = (url: string, target: '_self' | '_blank') => window.open(url, target)
  const { setAppTheme, appTheme, featureType, turnOnReloadActive } = props;
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const { leadsExpanded, showLostLeads, showDeactivatedClients } = useAppSelector(selectUiState);

  const [isRightActionOpen, setIsRightActionOpen] = useState<boolean>(false);
  const [isRefreshingPage, setIsRefreshingPage] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [createLeadDialogOpen, setCreateLeadDialogOpen] = useState<boolean>(false);
  const [createClientDialogOpen, setCreateClientDialogOpen] = useState<boolean>(false);
  const [createJobDialogOpen, setCreateJobDialogOpen] = useState<boolean>(false);
  const [createTaskDialogOpen, setCreateTaskDialogOpen] = useState<boolean>(false);
  const [createEquipmentOrdersDialogOpen, setCreateEquipmentOrdersDialogOpen] = useState<boolean>(false);
  const [isCalculatorOpen, setIsCalculatorOpen] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const sentLeadFilterSuccess = useAppSelector(selectHttpState)[CORRELATION_IDS.SEND_LEAD_FILTER];
  const jobListResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_JOB_LIST];

  const jobListTypeSelection = useJobListType();

  const getLeadList = (type: 'lostLead' | 'lead') =>
    getLeads({
      limit: 25,
      page: 1,
      type,
    });

  const getClientList = (type: 'deactivatedClient' | 'clients') =>
    getClients({
      limit: 25,
      page: 1,
      type,
    });

  const getJobList = (type: UiState['jobListType']) =>
    getJobs({
      limit: 25,
      page: 1,
      type,
    });
  const getEquipmentOrdersList = () =>
    getEquipmentOrdersOpen({
      limit: 6,
      page: 1,
    });
  const equipmentOrdersCompleted = () =>
    getEquipmentOrdersCompleted({
      limit: 6,
      page: 1,
    });
  const equipmentOrdersCancelled = () =>
    getEquipmentOrdersCancelled({
      limit: 6,
      page: 1,
    });
  const openCreateLeadDialog = () => {
    setCreateLeadDialogOpen(true);
  };
  const openCreateClientDialog = () => {
    setCreateClientDialogOpen(true);
  };
  const closeCreateClientDialog = () => {
    setCreateClientDialogOpen(false);
  };
  const openCreateJobDialog = () => {
    setCreateJobDialogOpen(true);
  };
  const openCreateTaskDialog = () => {
    setCreateTaskDialogOpen(true);
  };
  const openCreateEquipmentOrdersDialog = () => {
    setCreateEquipmentOrdersDialogOpen(true);
  };
  const closeCreateLeadDialog = () => {
    setCreateLeadDialogOpen(false);
  };
  const closeCreateJobDialog = () => {
    setCreateJobDialogOpen(false);
  };
  const closeCreateTaskDialog = () => {
    setCreateTaskDialogOpen(false);
  };
  const closeEquipmentOrdersDialog = () => {
    setCreateEquipmentOrdersDialogOpen(false);
  };
  const handleCalculatorDialog = () => {
    setIsCalculatorOpen(true);
  };
  const handleCalculatorDialogClose = () => {
    setIsCalculatorOpen(false);
  };
  const RightAction = () => {
    setIsRightActionOpen(!isRightActionOpen);
    setIsMenuOpen(false);
  };
  const MenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsRightActionOpen(false);
  };
  const FilterOpen = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const refreshPage = () => {
    setIsRefreshingPage(!isRefreshingPage);

    if (featureType === 'leads') {
      getLeadList(showLostLeads ? 'lostLead' : 'lead');
    }

    if (featureType === 'tasks') {
      getTasks({
        limit: 25,
        page: 1,
      });
    }

    if (featureType === 'clients') {
      getClientList(showDeactivatedClients ? 'deactivatedClient' : 'clients');
    }

    if (featureType === 'jobs') {
      getJobList(jobListTypeSelection.currentSelected);
    }

    if (featureType === 'equipment') {
      getEquipmentOrdersList();
      equipmentOrdersCompleted();
      equipmentOrdersCancelled();
    }
    if (featureType === 'ip') {
      if (turnOnReloadActive) {
        turnOnReloadActive();
      }
    }
    if (featureType === 'reports') {
      setReportGlobalReload(true);
    }
  };
  const toggleLeadsExpanded = () => {
    expandLeads(!leadsExpanded);
  };
  const toggleLeadTypes = () => {
    const show = !showLostLeads;
    toggleLostLeads(show);
    getLeadList(show ? 'lostLead' : 'lead');
  };

  const toggleClientTypes = () => {
    const show = !showDeactivatedClients;
    toggleDeactivatedClients(show);
    getClientList(show ? 'deactivatedClient' : 'clients');
  };

  const toggleJobTypes = () => {
    let jobType: UiState['jobListType'] = 'regular';
    switch (jobListTypeSelection?.currentSelected) {
      case 'regular':
        jobType = 'completed';
        break;
      case 'completed':
        jobType = 'removed';
        break;
      case 'removed':
        jobType = 'regular';
        break;
    }

    setJobListType(jobType);
    getJobList(jobType);
  };

  const handleClickToggleTheme = () => {
    if (setAppTheme) {
      setAppTheme(appTheme === 'light' ? 'dark' : 'light');
    }
  };

  const {
    bind: bindSearch,
    value: searchValue,
    setValue: setSearchValue,
  } = useInput('', {
    onChange(value: any) {
      setGlobalSearch(value);
    },
  });

  const resetSearchField = () => {
    setSearchValue('');
    setGlobalSearch('');
  };

  useEffect(() => {
    if (sentLeadFilterSuccess?.success) {
      setIsFilterOpen(false);
    }
  }, [sentLeadFilterSuccess]);

  useEffect(() => {
    return () => {
      setGlobalSearch('');
    };
  }, []);

  return (
    <>
      <Box className={classes.root}>
        <div className={classes.appBar}>
          <div className={classes.navWrapper}>
            <div className={classes.wrapperLeftSide}>
              <span onClick={() => navigate(routePaths.root)} className={classes.brandLogo}>
                <img src={Logo} alt='WP-Logo' />
              </span>
              <div className={isMenuOpen ? classes.itemList + ' menuShow' : classes.itemList}>
                <span onClick={() => setIsMenuOpen(false)} className={classes.closeIcon2}>
                  <CloseIcon />
                </span>
                <span onClick={() => navigate(routePaths.leads)} className={classes.item}>
                  <span className={splitLocation[1] === 'leads' ? 'active' : ''}>
                    {showLostLeads && featureType === 'leads' ? 'Lost Leads' : 'Leads'}
                  </span>
                </span>
                <span onClick={() => navigate(routePaths.clients)} className={classes.item}>
                  <span className={splitLocation[1] === 'clients' ? 'active' : ''}>
                    {showDeactivatedClients && featureType === 'clients' ? 'Deactivated Clients' : 'Clients'}
                  </span>
                </span>
                <span onClick={() => navigate(routePaths.jobs)} className={classes.item}>
                  <span className={splitLocation[1] === 'jobs' ? 'active' : ''}>
                    {jobListTypeSelection?.showRegular && 'Jobs'}
                    {jobListTypeSelection?.showCompleted && 'Completed Jobs'}
                    {jobListTypeSelection?.showRemoved && 'Removed Jobs'}
                  </span>
                </span>
                <span onClick={() => navigate(routePaths.ip)} className={classes.item}>
                  <span className={splitLocation[1] === 'ip' ? 'active' : ''}>IP</span>
                </span>
                <span onClick={() => navigate(routePaths.tasks)} className={classes.item}>
                  <span className={splitLocation[1] === 'tasks' ? 'active' : ''}>TTasks</span>
                </span>
                <span onClick={() => _navigate(routePaths.dTasks, '_self')} className={classes.item}>
                  <span className={splitLocation[1] === 'd-tasks' ? 'active' : ''}>DTasks</span>
                </span>
                <span onClick={() => navigate(routePaths.equipmentOrders)} className={classes.item}>
                  <span className={splitLocation[1] === 'equipment-orders' ? 'active' : ''}>Orders</span>
                </span>
                <span onClick={() => navigate(routePaths.reports)} className={classes.item}>
                  <span className={splitLocation[1] === 'reports' ? 'active' : ''}>Reports</span>
                </span>
                {props.hideHeaderProfile && (
                  <span className={classes.item}>
                    <span className={splitLocation[1] === 'profile' ? 'active' : ''}>Profile</span>
                  </span>
                )}
              </div>
            </div>
            <div className={classes.wrapperLeftSide}>
              <span onClick={MenuOpen} className={classes.menu}>
                <MenuIcon />
              </span>
              <span onClick={RightAction} className={classes.filterMenu}>
                <ManageSearchIcon />
              </span>
            </div>
            {props.hideHeaderRightSide && (
              <div className={isRightActionOpen ? classes.wrapperRightSide + ' showFilter' : classes.wrapperRightSide}>
                <span className={classes.wrapperRight}>
                  <span onClick={() => setIsRightActionOpen(false)} className={classes.closeIcon}>
                    <CloseIcon />
                  </span>
                  <div className={classes.rightChild}>
                    {featureType !== 'ip' && featureType !== 'reports' && (
                      <button
                        onClick={
                          featureType === 'leads'
                            ? openCreateLeadDialog
                            : featureType === 'clients'
                              ? openCreateClientDialog
                              : featureType === 'jobs'
                                ? openCreateJobDialog
                                : featureType === 'tasks'
                                  ? openCreateTaskDialog
                                  : featureType === 'equipment'
                                    ? openCreateEquipmentOrdersDialog
                                    : () => {}
                        }
                      >
                        <ControlPointIcon />
                      </button>
                    )}

                    <button onClick={handleClickToggleTheme}>
                      <StarHalfIcon />
                    </button>

                    {featureType === 'leads' && (
                      <button onClick={toggleLeadTypes}>
                        {showLostLeads ? <DeleteForeverIcon /> : <FlashOnIcon />}
                      </button>
                    )}

                    {featureType === 'clients' && (
                      <button onClick={toggleClientTypes}>
                        {showDeactivatedClients ? <DeleteForeverIcon /> : <FlashOnIcon />}
                      </button>
                    )}

                    {featureType === 'jobs' && (
                      <button onClick={toggleJobTypes} disabled={jobListResponse?.loading}>
                        {jobListTypeSelection?.showRegular && <FlashOnIcon />}
                        {jobListTypeSelection?.showCompleted && <DoneAllIcon />}
                        {jobListTypeSelection?.showRemoved && <DeleteForeverIcon />}
                      </button>
                    )}

                    {(featureType === 'leads' || featureType === 'clients' || featureType === 'jobs') && (
                      <button onClick={toggleLeadsExpanded}>
                        {leadsExpanded ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </button>
                    )}

                    <button onClick={refreshPage} className={isRefreshingPage ? 'loop' : 'reverse'}>
                      <LoopIcon />
                    </button>

                    {(featureType === 'leads' || featureType === 'clients' || featureType === 'jobs') && (
                      <button onClick={FilterOpen}>
                        <FilterListIcon />
                      </button>
                    )}
                  </div>

                  <div className={isFilterOpen ? `${classes.show}` : `${classes.hide}`}>
                    {isFilterOpen && (
                      <Filter
                        isFilterOpen={isFilterOpen}
                        onClose={() => setIsFilterOpen(false)}
                        featureType={featureType}
                      />
                    )}
                  </div>

                  <div
                    className={classes.searchBody}
                    style={{
                      width: featureType === 'ip' || featureType === 'equipment' ? 'auto' : '',
                    }}
                  >
                    {featureType !== 'ip' && featureType !== 'equipment' && (
                      <div className={classes.inputField}>
                        <TextInput
                          variant={'standard'}
                          placeholder={''}
                          fullWidth={true}
                          autoComplete='off'
                          {...bindSearch}
                        />
                        <IconButton className={'bg_transparent'} type='submit' sx={{ p: '10px' }} aria-label='search'>
                          <SearchIcon className={'search_icon'} />
                        </IconButton>
                        {searchValue && searchValue !== '' && (
                          <IconButton
                            className={classNames('bg_transparent close', {})}
                            sx={{ p: '10px' }}
                            aria-label='close'
                            onClick={resetSearchField}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                      </div>
                    )}
                    <AttachMoneyIcon onClick={handleCalculatorDialog} className={classes.dollarIcon} />
                  </div>
                </span>
              </div>
            )}
          </div>

          {/* <CreateClient open={createClientDialogOpen} onClose={closeCreateClientDialog} mode={'create'} /> */}
          <CreateLead
            open={createClientDialogOpen}
            onClose={closeCreateClientDialog}
            mode={'create'}
            title={'Client'}
          />

          <CreateLead open={createLeadDialogOpen} onClose={closeCreateLeadDialog} mode={'create'} title={'Lead'} />

          <CreateJob open={createJobDialogOpen} onClose={closeCreateJobDialog} mode={'create'} />

          <CreateTaskDialog open={createTaskDialogOpen} onClose={closeCreateTaskDialog} mode={'create'} />
          <CreateEquipmentOrders
            open={createEquipmentOrdersDialogOpen}
            onClose={closeEquipmentOrdersDialog}
            mode={'create'}
          />
          <Calculator openDialog={isCalculatorOpen} onClick={handleCalculatorDialogClose} />
        </div>
      </Box>
    </>
  );
};

const CustomHeader: React.FC<HeaderProps> = withAppTheme(Header);
export { CustomHeader as Header };
