import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { LeadItem, ProgressItem } from '@/types';
import {
  clearUpdateProgressList,
  updateProgressList,
  getLeadEquipments,
  openConfirmationDialog,
  moveLead,
  clearMovedLeadResponse,
  getLeads,
} from '@/redux/actions';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import toast from 'react-hot-toast';
import { selectUiState } from '@/redux/reducers/uiSlice';
import { InstallPopUP } from '@/components/organisms/Leads/InstallPopUP';

interface LeadColumnProps {
  disabled?: boolean;
  leadId?: LeadItem['id'];
  progressItem?: ProgressItem;
  active?: boolean;
  statusChangeable?: boolean;
  oldProgressBars?: string[];

  onUpdateStart?(targetId: any): void;

  onUpdateFinished?(): void;

  onSuccess?(progressBars: string[]): void;

  leadData?: LeadItem;
  jobProgressBars?: ProgressItem[];

  reloadList?(): void;
}

const LeadColumn: React.FC<LeadColumnProps> = (props) => {
  const {
    progressItem,
    leadId,
    active,
    disabled,
    oldProgressBars,
    onSuccess,
    leadData,
    onUpdateStart,
    onUpdateFinished,
    reloadList,
    jobProgressBars,
  } = props;

  const [updateStatusLoading, setUpdateStatusLoading] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(active ?? false);
  const [installPopupOpen, setInstallPopupOpen] = useState(false);
  const updateProgressListItems = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_PROGRESS_LIST];
  const createEquipmentResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.CREATE_LEAD_EQUIPMENTS];
  const moveLeadResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.MOVE_LEAD];
  const { showLostLeads } = useAppSelector(selectUiState);
  const [targetId, setTargetId] = useState<LeadItem['id'] | null>(null);
  const [targetProgressName, setTargetProgressName] = useState<string | null | undefined>(null);

  const getLeadList = () =>
    getLeads({
      limit: 25,
      page: 1,
      type: showLostLeads ? 'lostLead' : 'lead',
    });

  const updateStatus = (active: boolean | null = null) => {
    if (!props?.statusChangeable) {
      return;
    }

    const __active = active !== null ? active : !isActive;
    let data: ProgressItem = {
      id: leadId,
    };

    if (__active) {
      data.new_progress_bar = progressItem?.id;
      data.old_progress_bars = oldProgressBars;
    } else {
      data.old_progress_bars = [];

      [...(oldProgressBars ?? [])]?.forEach((item) => {
        if (progressItem?.id !== item) {
          data.old_progress_bars.push(item);
        }
      });

      const oldProgressBarLastIndex = data?.old_progress_bars?.length - 1;
      if (oldProgressBarLastIndex > -1) {
        data.new_progress_bar = data.old_progress_bars[data?.old_progress_bars?.length - 1] ?? null;
        data.old_progress_bars.splice(oldProgressBarLastIndex, 1);
      }
    }

    if (data.old_progress_bars?.length === 0) {
      if (jobProgressBars && jobProgressBars?.length > 0) {
        const index = jobProgressBars?.findIndex((jpb) => jpb?.progress_name === 'Fresh');
        if (index > -1) {
          data.old_progress_bars.push(jobProgressBars[index]?.id);
        }
      }
    }

    setTargetId(leadId);
    setUpdateStatusLoading(true);
    updateProgressList(data);

    if (onUpdateStart) {
      onUpdateStart(leadId);
    }

    setIsActive(__active);
  };

  const handleCloseInstallPopup = () => {
    setInstallPopupOpen(false);
  };

  useEffect(() => {
    if (updateProgressListItems?.success && updateStatusLoading && targetId === leadId) {
      setUpdateStatusLoading(false);
      // setTargetId(null);

      if (onSuccess) {
        const newActiveProgressList = Array.isArray(updateProgressListItems?.data) ? updateProgressListItems?.data : [];
        const progressItems: string[] = [];

        newActiveProgressList?.forEach((item: ProgressItem) => {
          if (item?.id) {
            progressItems?.push(item?.id);
          }
        });

        if (targetProgressName === 'Follow Up Call') {
          moveLead({
            leadId,
            name: 'Clients',
          });
        }

        setTargetProgressName(null);
        onSuccess(progressItems);
      }

      if (onUpdateFinished) {
        onUpdateFinished();
      }

      clearUpdateProgressList();
    }

    if (updateProgressListItems?.error && updateStatusLoading && targetId === leadId) {
      toast.error('Failed to update the status!');
      setIsActive(!isActive);
      if (onUpdateFinished) {
        onUpdateFinished();
      }

      setTargetProgressName(null);
      clearUpdateProgressList();
    }
  }, [updateProgressListItems, updateStatusLoading, targetId, leadId]);

  useEffect(() => {
    if (targetId === leadId) {
      if (moveLeadResponse?.success) {
        clearMovedLeadResponse();

        if (reloadList) {
          reloadList();
        }
      }

      if (moveLeadResponse?.error) {
        toast.error('Failed to move lead!');
        clearMovedLeadResponse();
      }
    }
  }, [moveLeadResponse]);

  useEffect(() => {
    setIsActive(active ?? false);
  }, [active]);

  return (
    <>
      <td
        className={classNames({
          active: isActive,
          lostLeads: showLostLeads || disabled,
        })}
        onClick={() => {
          if (progressItem) {
            setTargetProgressName(progressItem?.progress_name);

            if (progressItem?.progress_name === 'Installed') {
              setInstallPopupOpen(true);
              if (leadId) {
                getLeadEquipments(leadId);
              }
              return;
            }

            if (progressItem?.progress_name === 'Follow Up Call') {
              openConfirmationDialog({
                message: 'Would you like to move to client section?',
                type: 'success',
                cancelType: 'error',
                confirmLabel: 'Yes',
                cancelLabel: 'No',
                textCenter: 'center',
                onClickCancel() {},
                onClickConfirm() {
                  updateStatus();
                },
              });

              return;
            }
            if (progressItem?.progress_name === 'Client Drip' && !isActive) {
              openConfirmationDialog({
                message: 'Would you like to move lead to client drip email campaign?',
                type: 'success',
                cancelType: 'error',
                confirmLabel: 'Yes',
                cancelLabel: 'No',
                textCenter: 'center',
                onClickCancel() {
                  updateStatus(false);
                },
                onClickConfirm() {
                  updateStatus(true);
                },
              });

              return;
            }

            updateStatus();
          }
        }}
      >
        <button>{progressItem?.progress_name}</button>
      </td>

      {progressItem?.progress_name === 'Installed' && (
        <InstallPopUP
          open={installPopupOpen}
          onClose={handleCloseInstallPopup}
          leadId={leadId}
          leadData={leadData}
          onChangeEquipments={(leadEquipments) => {
            if (leadEquipments) {
              updateStatus(leadEquipments?.length > 0);
            }
          }}
        />
      )}
    </>
  );
};

export { LeadColumn };
