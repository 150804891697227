import React, { useEffect, useState } from 'react';
import { useTaskStyles } from '@/static/stylesheets/molecules/Tasks';
import {
  clearChangeTaskPriorityResponse,
  clearDeletedTask,
  clearMarkTaskAsCompleted,
  clearStartStopTaskTimerResponse,
  getTasks,
  getTaskStaffMembers,
} from '@/redux/actions';
import { Pagination } from '@/components/atoms/Pagination';
import { useAppSelector, useThrottle } from '@/hooks';
import { selectUiState } from '@/redux/reducers/uiSlice';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { convertToNumber } from '@/utils';
import { TaskItem } from '@/types';
import { TaskGridLoader } from '@/components/organisms/Tasks';
import { TaskGridRow } from '@/components/molecules/Tasks/TasksGrid';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';

interface TasksGridProps {}

const TasksGrid: React.FC<TasksGridProps> = () => {
  const classes = useTaskStyles();
  const { globalSearch } = useAppSelector(selectUiState);
  const taskSearchQuery = useThrottle(globalSearch, 1000);

  const tasksResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_TASKS];
  const taskListFilterResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_FILTERED_TASKS];
  const taskDeleteResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_TASK];
  const taskMarkAsCompletedResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.MARK_TASK_AS_COMPLETED];
  const taskChangePriorityResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.CHANGE_TASK_PRIORITY];
  const taskTimerToggleResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.START_STOP_TASK_TIMER];

  const tasks: TaskItem[] = taskListFilterResponse?.data?.data
    ? taskListFilterResponse?.data?.data ?? []
    : tasksResponse?.data?.data ?? [];
  const totalPages = convertToNumber(
    taskListFilterResponse?.data ? taskListFilterResponse?.data?.last_page : tasksResponse?.data?.last_page,
  );

  const showAllSubTasks = taskListFilterResponse?.payload?.show_child_tasks;

  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getStaffMemberList = () =>
    getTaskStaffMembers({
      limit: 'all',
      page: 1,
    });

  const getTaskList = (search?: string) =>
    getTasks({
      limit,
      page: currentPage,
      s: search,
    });

  useEffect(() => {
    if (taskDeleteResponse?.success) {
      toast.success(alertMessages.tasks.taskDeleteSuccess);
      getTaskList();
      clearDeletedTask();
    }
  }, [taskDeleteResponse?.success]);

  useEffect(() => {
    if (taskDeleteResponse?.error) {
      toast.error(alertMessages.tasks.taskDeleteError);
      clearDeletedTask();
    }
  }, [taskDeleteResponse?.error]);

  useEffect(() => {
    if (taskMarkAsCompletedResponse?.success) {
      toast.success(alertMessages.tasks.taskMarkedAsCompletedSuccess);
      getTaskList();
      clearMarkTaskAsCompleted();
    }
  }, [taskMarkAsCompletedResponse?.success]);

  useEffect(() => {
    if (taskMarkAsCompletedResponse?.error) {
      const error = taskMarkAsCompletedResponse?.error;
      if (error?.status === 422) {
        toast.error(alertMessages.tasks.taskMarkedAsCompletedUnfinishedChildrenError);
      } else {
        toast.error(alertMessages.tasks.taskMarkedAsCompletedError);
      }
      clearMarkTaskAsCompleted();
    }
  }, [taskMarkAsCompletedResponse?.error]);

  useEffect(() => {
    if (taskChangePriorityResponse?.success) {
      getTaskList();
      clearChangeTaskPriorityResponse();
    }
  }, [taskChangePriorityResponse?.success]);

  useEffect(() => {
    if (taskChangePriorityResponse?.error) {
      clearChangeTaskPriorityResponse();
    }
  }, [taskChangePriorityResponse?.error]);

  useEffect(() => {
    if (taskTimerToggleResponse?.success) {
      const data = taskTimerToggleResponse?.data;
      toast.success(
        data?.end_time === null ? alertMessages.tasks.taskTimerStartSuccess : alertMessages.tasks.taskTimerStopSuccess,
      );
      getTaskList();
      clearStartStopTaskTimerResponse();
    }
  }, [taskTimerToggleResponse?.success]);

  useEffect(() => {
    if (taskTimerToggleResponse?.error) {
      clearStartStopTaskTimerResponse();
    }
  }, [taskTimerToggleResponse?.error]);

  useEffect(() => {
    if (!initialRender) {
      getTaskList(taskSearchQuery ?? '');
    }
  }, [taskSearchQuery]);

  useEffect(() => {
    if (!initialRender) {
      getTaskList();
    }
  }, [currentPage]);

  useEffect(() => {
    setInitialRender(false);
    getTaskList();
    getStaffMemberList();
  }, []);

  return (
    <div className={classes.tasksGridContainer}>
      <div className={'table-wrapper'}>
        <table>
          <thead>
            <tr>
              <th style={{ width: '60px' }}>Status</th>
              <th style={{ width: '280px' }}>Task</th>
              <th style={{ width: '70px' }}>Priority</th>
              <th style={{ width: '140px' }}>Type</th>
              <th style={{ width: '120px' }}>Client Assigned</th>
              <th style={{ width: '95px' }}>Days Active</th>
              <th style={{ width: '80px' }}>Due Date</th>
              <th style={{ width: '200px' }}>Assigned To</th>
              <th style={{ width: '250px' }}>Actions</th>
            </tr>
          </thead>
          <tbody className={'task_table_list'}>
            {tasksResponse?.loading ? (
              <TaskGridLoader />
            ) : (
              <>
                {tasks &&
                  tasks?.length > 0 &&
                  tasks?.map((task, index) => (
                    <TaskGridRow
                      key={index}
                      taskData={task}
                      reloadList={getTaskList}
                      childrenExpanded={showAllSubTasks}
                    />
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>

      <div className={classes.tasksPaginationContainer}>
        <Pagination count={totalPages} currentPage={currentPage} onChange={(value) => setCurrentPage(value)} />
      </div>
    </div>
  );
};

export { TasksGrid };
