import { Button } from '@/components/atoms/Button';
import { MenuDialog } from '@/components/atoms/Popover/MenuDialog';
import { EmailTemplatesForm } from '@/components/molecules/Settings/Email/EmailTemplates';
import { ContractModal } from '@/components/organisms/Leads/ContractModal';
import {
  ProposalPackageItem,
  ProposalTermItem,
} from '@/components/organisms/Leads/Expand/PersonalInfo/ProposalAndTerms';
import { RemoveAction } from '@/components/organisms/Leads/Expand/modal/RemoveAction';
import { alertMessages } from '@/config';
import { useAppSelector } from '@/hooks';
import {
  clearLeadSendContractResponse,
  clearLeadUpdateCustomerDetails,
  clearLeadUpdatedVMCount,
  clearRestoreLostLead,
  clearUpdatedCustomerLead,
  deleteLostLeadAndClientAction,
  getLeads,
  restoreLostLead,
  updateCustomerLead,
  updateLeadCustomerDetails,
  updateLeadVMCount,
} from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { selectUiState } from '@/redux/reducers/uiSlice';
import { useLeadStyles } from '@/static/stylesheets/organisms/Leads/leadStyles';
import { convertEmptyStringToNull } from '@/utils';
import { convertToPayloadParam } from '@/utils/convertToPayloadParam';
import { timezone } from '@/utils/timeZone';
import BlockIcon from '@mui/icons-material/Block';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MouseIcon from '@mui/icons-material/Mouse';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

interface HeaderActionProps {
  leadsData?: any;

  onToggleOptions?(): void;

  onToggleFilesMenu?(): void;

  reloadActivityLogList?(): void;

  expandActivity?: boolean;
  filesMenuOpen?: boolean;
  isLostLead?: boolean;
  isSaveEnabled?: boolean;
}

const RightHeader: React.FC<HeaderActionProps> = (props) => {
  const classes = useLeadStyles();
  const updateVMCountResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_LEAD_VM_COUNT];
  const restoresLostLeadResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.RESTORE_LOST_LEAD];
  // const getContractResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_CONTRACT_PDF];
  const updateCustomerLeadResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_CUSTOMER_LEAD];
  const updateCustomerDetailsResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_LEAD_CUSTOMER_DETAILS];
  const sendsContractResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.SEND_LEAD_CONTRACT];
  const deleteClientAndLeadResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_LOST_LEAD_AND_CLIENT];
  const [targetId, setTargetId] = useState(null);
  const { onToggleOptions, onToggleFilesMenu, expandActivity, filesMenuOpen } = props;
  const { showLostLeads } = useAppSelector(selectUiState);
  const leadsForm: any = useAppSelector(selectUiState)?.leadsForm;

  const actionsDisabled = showLostLeads || props?.isLostLead;

  // const leadsForm = leadsFormData?.payload ?? [];
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpen] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [isPDFData, setIsPDFData] = useState(null);
  const [modalValue, setModalValue] = useState('');
  const [isOpenDeleteAlert, setIsOpenDeleteAlert] = React.useState(false);

  const handleOpenModal = (e: any) => {
    const value = e.target.id;
    if (value !== 'VM' && value !== 'duplicate' && value) {
      setOpen(true);
    }
    if (value === 'VM') {
      setTargetId(props.leadsData?.id);
      updateLeadVMCount(props.leadsData?.id);
    }
    setModalValue(value);

    if (value === 'duplicate') {
      const data: any = {
        removed_reason: 'duplicate',
        removed_reason_details: 'duplicate',
        time_zone: timezone,
      };
      data.id = props.leadsData?.id;
      setTargetId(props.leadsData?.id);
      updateCustomerLead(data);
    }
  };

  const handleCloseModal = () => setOpen(false);

  const handleDialog = (data: any) => {
    setOpenDialog(true);
    setIsPDFData(data);
    setTargetId(data?.id);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsPDFData(null);
  };
  const handleEmailDialog = () => {
    setOpenEmailDialog(true);
  };
  const handleEmailDialogClose = () => {
    setOpenEmailDialog(false);
  };

  const handleRestoresLostLead = () => {
    setTargetId(props.leadsData?.id);
    restoreLostLead(props.leadsData?.id);
  };

  const handleDeleteLostLead = () => {
    setIsOpenDeleteAlert(true);
  };

  const submitToDeleteLostLead = () => {
    setTargetId(props.leadsData?.id);
    deleteLostLeadAndClientAction(props.leadsData.id);
    setIsOpenDeleteAlert(false);
  };

  const onSubmit = () => {
    if (
      leadsForm?.survey_datetime_from?.trim().split(/\s+/).length > 4 ||
      leadsForm?.survey_datetime_to?.trim().split(/\s+/).length > 4 ||
      leadsForm?.installation_datetime_from?.trim().split(/\s+/).length > 4 ||
      leadsForm?.installation_datetime_to?.trim().split(/\s+/).length > 4 ||
      leadsForm?.next_follow_up_datetime?.trim().split(/\s+/).length > 4
    ) {
      toast.error('Please select date!');
      return;
    }
    /*if (
      leadsForm?.survey_datetime_from?.trim().split(/\s+/).length === 1 ||
      leadsForm?.survey_datetime_to?.trim().split(/\s+/).length === 1
    ) {
      toast.error("Please select survey date time (from and to)!");
      return;
    }*/

    /*if (
      leadsForm?.installation_datetime_from?.trim().split(/\s+/).length === 1 ||
      leadsForm?.installation_datetime_to?.trim().split(/\s+/).length === 1
    ) {
      toast.error("Please select installation date time (from and to)!");
      return;
    }*/

    /*if (leadsForm?.next_follow_up_datetime?.trim().split(/\s+/).length === 1) {
      toast.error("Please select next followup date time!");
      return;
    }*/

    const packages: ProposalPackageItem[] = leadsForm?.packages ?? [];
    const terms: ProposalTermItem[] = leadsForm?.terms ?? [];

    if (packages && packages?.length > 0) {
      let error = false;

      packages?.forEach((item) => {
        if (item?.error) {
          error = true;
        }
      });

      if (error) {
        toast.error(alertMessages.leads.proposalSubmitValidationError);
        return;
      }
    }

    if (terms && terms?.length > 0) {
      let error = false;

      terms?.forEach((item) => {
        if (item?.error) {
          error = true;
        }
      });

      if (error) {
        toast.error(alertMessages.leads.proposalSubmitValidationError);
        return;
      }
    }

    if (
      leadsForm?.contract_download_bandwidth?.error ||
      leadsForm?.contract_upload_bandwidth?.error ||
      leadsForm?.contract_price?.error ||
      leadsForm?.contract_install_price?.error ||
      leadsForm?.additional_recurring_name?.error ||
      leadsForm?.additional_terms?.error ||
      leadsForm?.contract_validity?.error ||
      leadsForm?.additional_recurring_price?.error
    ) {
      toast.error(alertMessages.leads.contractSubmitValidationError);
      return;
    }

    if (
      leadsForm?.ap_connects_to?.error ||
      leadsForm?.private_ip?.error ||
      leadsForm?.private_ip_cidr?.error ||
      leadsForm?.private_gateway?.error ||
      leadsForm?.public_ip?.error ||
      leadsForm?.public_ip_cidr?.error
    ) {
      toast.error(alertMessages.leads.ipSubmitValidationError);
      return;
    }

    const survey_date = leadsForm?.survey_date;
    const survey_time_from = leadsForm?.survey_time_from;
    const survey_time_to = leadsForm?.survey_time_to;
    const survey_datetime_from = convertEmptyStringToNull(leadsForm?.survey_datetime_from);
    const survey_datetime_to = convertEmptyStringToNull(leadsForm?.survey_datetime_to);

    const installation_date = leadsForm?.installation_date;
    const installation_time_from = leadsForm?.installation_time_from;
    const installation_time_to = leadsForm?.installation_time_to;
    const installation_datetime_from = convertEmptyStringToNull(leadsForm?.installation_datetime_from);
    const installation_datetime_to = convertEmptyStringToNull(leadsForm?.installation_datetime_to);

    const next_follow_up_date = leadsForm?.next_follow_up_date;
    const next_follow_up_time = leadsForm?.next_follow_up_time;
    const next_follow_up_datetime = convertEmptyStringToNull(leadsForm?.next_follow_up_datetime);

    if (
      (survey_date && (!survey_time_from || !survey_time_to)) ||
      (survey_time_from && (!survey_date || !survey_time_to)) ||
      (survey_time_to && (!survey_date || !survey_time_from))
    ) {
      toast.error(alertMessages.leads.surveyDateTimeValidationError);
      return;
    }

    if (
      (installation_date && (!installation_time_from || !installation_time_to)) ||
      (installation_time_from && (!installation_date || !installation_time_to)) ||
      (installation_time_to && (!installation_date || !installation_time_from))
    ) {
      toast.error(alertMessages.leads.installationDateTimeValidationError);
      return;
    }

    if ((next_follow_up_date && !next_follow_up_time) || (next_follow_up_time && !next_follow_up_date)) {
      toast.error(alertMessages.leads.nextFollowUpDateTimeValidationError);
      return;
    }

    const data: any = {
      time_zone: timezone,
    };

    if (survey_datetime_from) {
      data.survey_datetime_from = survey_datetime_from;
    }

    if (survey_datetime_to) {
      data.survey_datetime_to = survey_datetime_to;
    }

    if (installation_datetime_from) {
      data.installation_datetime_from = installation_datetime_from;
    }

    if (installation_datetime_to) {
      data.installation_datetime_to = installation_datetime_to;
    }

    if (next_follow_up_datetime) {
      data.next_follow_up_datetime = next_follow_up_datetime;
    }

    if (packages?.length >= 0) {
      data.packages = packages;
    }

    if (terms?.length >= 0) {
      data.terms = terms;
    }

    if (leadsForm?.source_id !== '1') {
      data.source_id = leadsForm?.source_id;
    }
    data.contract_download_bandwidth = convertToPayloadParam(leadsForm?.contract_download_bandwidth?.value, null);
    data.contract_upload_bandwidth = convertToPayloadParam(leadsForm?.contract_upload_bandwidth?.value, null);
    data.contract_price = convertToPayloadParam(leadsForm?.contract_price?.value, null);
    data.contract_install_price = convertToPayloadParam(leadsForm?.contract_install_price?.value, null);
    data.contract_validity = convertToPayloadParam(leadsForm?.contract_validity?.value, null);
    data.additional_terms = convertToPayloadParam(leadsForm?.additional_terms?.value, null);
    data.additional_recurring_name = convertToPayloadParam(leadsForm?.additional_recurring_name?.value, null);
    data.additional_recurring_price = convertToPayloadParam(leadsForm?.additional_recurring_price?.value, null);
    if (leadsForm?.ap_connects_to?.value) {
      data.ap_connects_to = leadsForm?.ap_connects_to?.value;
    }
    if (leadsForm?.private_ip?.value) {
      data.private_ip = leadsForm?.private_ip?.value;
    }
    if (leadsForm?.private_ip_cidr?.value) {
      data.private_ip_cidr = leadsForm?.private_ip_cidr?.value;
    }
    if (leadsForm?.private_ip?.value) {
      data.private_ip = leadsForm?.private_ip?.value;
    }
    if (leadsForm?.private_gateway?.value) {
      data.private_gateway = leadsForm?.private_gateway?.value;
    }
    if (leadsForm?.public_ip?.value) {
      data.public_ip = leadsForm?.public_ip?.value;
    }
    if (leadsForm?.public_ip_cidr?.value) {
      data.public_ip_cidr = leadsForm?.public_ip_cidr?.value;
    }
    if (leadsForm?.follow_up_description) {
      data.follow_up_description = leadsForm?.follow_up_description;
    }

    data.id = props.leadsData?.id;
    setTargetId(props.leadsData?.id);
    updateLeadCustomerDetails(data);
  };

  const getLeadList = () =>
    getLeads({
      limit: 25,
      page: 1,
      type: showLostLeads ? 'lostLead' : 'lead',
    });

  useEffect(() => {
    if (updateVMCountResponse?.success && targetId === props.leadsData?.id) {
      toast.success(alertMessages.leads.vmCountUpdateSuccess);
      setTargetId(null);
      clearLeadUpdatedVMCount();
      if (updateVMCountResponse?.data?.shouldReload) {
        getLeadList();
      }

      if (props?.reloadActivityLogList) {
        props?.reloadActivityLogList();
      }
    }

    if (updateVMCountResponse?.error && targetId === props.leadsData?.id) {
      setTargetId(null);
      clearLeadUpdatedVMCount();
      toast.error(alertMessages.leads.vmCountUpdateError);
    }
  }, [updateVMCountResponse, targetId]);

  useEffect(() => {
    if (restoresLostLeadResponse?.success && targetId === props.leadsData?.id) {
      toast.success(alertMessages.leads.restoreLostLeadSuccess);
      setTargetId(null);
      clearRestoreLostLead();
      getLeadList();
    }

    if (restoresLostLeadResponse?.error && targetId === props.leadsData?.id) {
      toast.error(alertMessages.leads.restoreLostLeadError);
      clearRestoreLostLead();
    }
  }, [targetId, restoresLostLeadResponse]);

  useEffect(() => {
    if (deleteClientAndLeadResponse?.success && targetId === props.leadsData?.id) {
      toast.success(alertMessages.leads.deleteLeadSuccess);
      setTargetId(null);
      getLeadList();
    }

    if (deleteClientAndLeadResponse?.error && targetId === props.leadsData?.id) {
      toast.error(alertMessages.leads.deleteLeadError);
    }
  }, [targetId, deleteClientAndLeadResponse]);

  useEffect(() => {
    if (updateCustomerLeadResponse?.success && targetId === props.leadsData?.id) {
      toast.success(alertMessages.leads.customerLeadUpdateSuccess);
      setTargetId(null);
      setOpen(false);
      clearUpdatedCustomerLead();
      if (updateCustomerLeadResponse?.data?.shouldReload) {
        getLeadList();
      }
    }

    if (updateCustomerLeadResponse?.error && targetId === props.leadsData?.id) {
      toast.error(alertMessages.leads.customerLeadUpdateError);
      clearUpdatedCustomerLead();
    }
  }, [targetId, updateCustomerLeadResponse]);

  useEffect(() => {
    if (updateCustomerDetailsResponse?.success && targetId === props.leadsData?.id) {
      toast.success(alertMessages.leads.customerDetailsUpdateSuccess);
      setTargetId(null);
      clearLeadUpdateCustomerDetails();
    }

    if (updateCustomerDetailsResponse?.error && targetId === props.leadsData?.id) {
      toast.error(updateCustomerDetailsResponse?.error?.data?.message);
      clearLeadUpdateCustomerDetails();
    }
  }, [targetId, updateCustomerDetailsResponse]);

  useEffect(() => {
    if (isPDFData) {
      if (sendsContractResponse?.success && targetId === props.leadsData?.id) {
        // toast.success("Document sent to DocuSign Successfully");
        setTargetId(null);
        clearLeadSendContractResponse();
        setOpenDialog(false);
      }
      if (sendsContractResponse?.error && targetId === props.leadsData?.id) {
        toast.error(alertMessages.leads.contractSendError);
        setTargetId(null);
        clearLeadSendContractResponse();
      }
    }
  }, [sendsContractResponse, targetId]);

  const menuRemove = [
    {
      title: 'No LOS',
      icon: <VisibilityOffIcon />,
      action: 'los',
    },
    {
      title: 'Lost',
      icon: <BlockIcon />,
      action: 'lost',
    },
    {
      title: 'Duplicate',
      icon: <ControlPointDuplicateIcon />,
      action: 'duplicate',
    },
    {
      title: 'From Drip Workflow',
      icon: <EmailOutlinedIcon />,
      action: 'from_drip_workflow',
    },
    {
      title: 'Other',
      icon: <MoreHorizIcon />,
      action: 'other',
    },
  ];
  const menuVM = [
    {
      title: 'Misclick',
      icon: <MouseIcon />,
    },
    {
      title: 'Yes, I just left a VM',
      icon: <VoicemailIcon />,
      action: 'VM',
    },
  ];

  const openInNewTabUCRM = (url: any) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div className={classes.rightDetails}>
        <Button
          color={'success'}
          title={'SAVE'}
          onClick={onSubmit}
          disabled={!props?.isSaveEnabled || actionsDisabled}
          loading={updateCustomerDetailsResponse?.loading}
        />
        <span className={classes.vertLine}>|</span>
        <Button onClick={onToggleOptions} title={expandActivity ? 'Collapse' : 'Expand'} />
        <Button onClick={onToggleFilesMenu} title={filesMenuOpen ? 'Info' : 'Files'} />
        <Button disabled={actionsDisabled} onClick={() => openInNewTabUCRM(props.leadsData?.ucrm_url)} title={'UCRM'} />
        <MenuDialog
          disabled={actionsDisabled}
          onClick={handleOpenModal}
          title={'VM'}
          menuList={menuVM}
          leftAlign={'left_align'}
        />
        <Button disabled={actionsDisabled} onClick={() => handleEmailDialog()} title={'Email'} />
        <Button disabled={actionsDisabled} onClick={() => handleDialog(props.leadsData)} title={'Contract'} />
        <div className={'last__button'}>
          {actionsDisabled ? (
            <>
              <Button onClick={handleRestoresLostLead} title={'Restore'} />
              <Button onClick={handleDeleteLostLead} style={{ marginLeft: '4px' }} color='error' title={'Delete'} />
            </>
          ) : (
            <MenuDialog
              onClick={handleOpenModal}
              title={'Remove'}
              leftAlign={'left_align'}
              menuList={menuRemove}
              menuHeader={'Reason for Removing?'}
            />
          )}
        </div>

        <ContractModal open={openDialog} onClose={handleDialogClose} leadData={isPDFData} />

        <EmailTemplatesForm
          open={openEmailDialog}
          onClose={handleEmailDialogClose}
          isLeadEmail={true}
          leadData={props?.leadsData}
          featureType={'lead'}
        />
        <RemoveAction
          openModal={openModal}
          modalValue={modalValue}
          onClose={handleCloseModal}
          leadsData={props.leadsData}
        />
      </div>

      <Dialog className={classes.deleteModalRoot} open={isOpenDeleteAlert}>
        <DialogContent className='deleteModalContent'>
          <DialogContentText className='textContent'>
            Are you sure you want to permanently delete this lead forever? There is no undo button.
          </DialogContentText>
          <DialogActions className='deleteModalActions'>
            <Button color='warning' onClick={() => setIsOpenDeleteAlert(!isOpenDeleteAlert)} title={'No, Cancel'} />
            <Button color='error' onClick={submitToDeleteLostLead} title={'Yes, Permanently Delete'} />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export { RightHeader };
