import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useModalStyles } from '@/static/stylesheets/organisms';
import Modal from '@mui/material/Modal';
import { TextareaAutosize } from '@mui/material';
import { Button } from '@/components/atoms/Button';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid, RadioGroup } from '@mui/material';
import Divider from '@mui/material/Divider';
import { updateCustomerLead, clearUpdatedCustomerLead, getLeads } from '@/redux/actions';
import { useInput } from '@/hooks';
import toast from 'react-hot-toast';
import { options } from '@/config/constants';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { alertMessages } from '@/config';

interface RemoveActionProps {
  openModal: boolean;

  onClose?(): void;

  modalValue?: any;
  leadsData?: any;
}

const RemoveAction: React.FC<RemoveActionProps> = (props) => {
  const classes = useModalStyles();
  const { openModal, onClose, modalValue, leadsData } = props;
  const updatedCustomerLeadResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.UPDATE_CUSTOMER_LEAD];
  const [isReason, setIsReason] = useState<string | null>(null);
  //const [isError, setIsError] = useState(false);
  const [targetId, setTargetId] = useState(null);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const resetFields = () => {
    resetReasonDetails();
  };

  const handleChange = (val: any) => {
    setIsReason(val);
    //setIsError(false);
  };
  const {
    value: isReasonDetails,
    reset: resetReasonDetails,
    error: reasonDetailsError,
    bind: bindReasonDetails,
  } = useInput('', {
    required: 'Please enter Reason Details!',
    validate(value) {
      if ((isReason as any)?.toString()?.toLowerCase() === 'other' && value === '') {
        return 'Please enter the reason details!';
      }

      if ((modalValue as any)?.toString()?.toLowerCase() === 'other' && value === '') {
        return 'Please enter the reason details!';
      }

      return true;
    },
  });

  const onSubmit = () => {
    // console.log(isReasonDetails,'ddd',isReason,'dddda', modalValue)
    let reason = isReason
    if (modalValue === 'from_drip_workflow') {
      reason = 'From drip workflow';
    }
    if (reason === null && modalValue !== 'other') {
      toast.error('Please at least one field selected!');
      return;
    }
    if (isReasonDetails === '' && modalValue === 'other') {
      toast.error('Please fill all the required fields!');
      return;
    }
    reasonDetailsError?.check();
    if (reasonDetailsError?.check() && (reason as any)?.toString()?.toLowerCase() === 'other') {
      toast.error('Please fill all the required fields!');
      return;
    }

    const data: any = {
      removed_reason: modalValue === 'los' ? 'no_los' : modalValue,
      removed_reason_details: isReasonDetails ? isReasonDetails : reason,
      time_zone: timezone,
    };
    setTargetId(leadsData?.id);
    data.id = leadsData?.id;
    updateCustomerLead(data);
  };
  const onSendArchive = () => {
    const data: any = {
      removed_reason: modalValue === 'los' ? 'no_los' : modalValue,
      removed_reason_details: modalValue === 'los' ? 'no_los' : modalValue,
      time_zone: timezone,
      should_send_mail: false,
    };
    setTargetId(leadsData?.id);
    data.id = leadsData?.id;
    updateCustomerLead(data);
  };
  const onSendEmail = () => {
    const data: any = {
      removed_reason: modalValue === 'los' ? 'no_los' : modalValue,
      removed_reason_details: modalValue === 'los' ? 'no_los' : modalValue,
      time_zone: timezone,
      should_send_mail: true,
    };
    setTargetId(leadsData?.id);
    data.id = leadsData?.id;
    updateCustomerLead(data);
  };

  const getLeadList = () =>
    getLeads({
      limit: 25,
      page: 1,
      type: 'lead',
    });

  useEffect(() => {
    if (!openModal) {
      resetFields();
    }
  }, [openModal]);

  useEffect(() => {
    if (updatedCustomerLeadResponse?.success && targetId === leadsData?.id) {
      toast.success(alertMessages.leads.customerLeadUpdateSuccess);
      setTargetId(null);
      clearUpdatedCustomerLead();
      if (updatedCustomerLeadResponse?.data?.shouldReload) {
        getLeadList();
      }

      if (onClose) {
        onClose();
      }
    }

    if (updatedCustomerLeadResponse?.error && targetId === leadsData?.id) {
      toast.error(alertMessages.leads.customerLeadUpdateError);
      clearUpdatedCustomerLead();
    }
  }, [updatedCustomerLeadResponse]);
  return (
    <>
      <Modal
        open={openModal}
        onClose={onClose}
        className={classes.root}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {modalValue === 'los'
              ? 'Would you like to send the NoLOS email to the lead?'
              : modalValue === 'lost'
              ? 'Choose the answer that best fits:'
              : modalValue === 'from_drip_workflow'
              ? 'From drip workflow ?'
              : 'Please briefly describe why you chose other'}
            {modalValue === 'los' && <Divider />}
          </Typography>
          {modalValue !== 'los' && modalValue !== 'from_drip_workflow' && (
            <>
              {modalValue === 'lost' && (
                <Grid container spacing={2} className={'radio'}>
                  {options &&
                    options?.length > 0 &&
                    options?.map((option: any, index: number) => (
                      <Grid key={index} item sm={12} md={6} lg={6}>
                        <RadioGroup
                          aria-labelledby='demo-controlled-radio-buttons-group'
                          name='controlled-radio-buttons-group'
                          value={isReason}
                          onChange={() => handleChange(option?.value)}
                        >
                          <FormControlLabel control={<Radio />} label={option?.label} value={option?.value} />
                        </RadioGroup>
                      </Grid>
                    ))}
                  {/* <Grid item sm={12} md={12} lg={12}>
                    { isError &&
                      <span className='error'>Please fill all the required fields!</span>
                    }
                  </Grid> */}
                </Grid>
              )}
              <TextareaAutosize
                aria-label='minimum height'
                minRows={4}
                placeholder='Textarea'
                {...bindReasonDetails}
                className={reasonDetailsError?.error ? 'textaria_error' : 'textaria'}
              />
              {reasonDetailsError?.error && <span className='error'>{reasonDetailsError?.message}</span>}
            </>
          )}
          <div className={'actionButton'}>
            <Button
              color={'error'}
              variant={'contained'}
              title={modalValue === 'from_drip_workflow' ? 'No' : 'Cancel'}
              onClick={onClose}
            />
            {modalValue !== 'los' && (
              <Button
                color={'success'}
                variant={'contained'}
                title={modalValue === 'from_drip_workflow' ? 'Yes' : 'Submit'}
                onClick={onSubmit}
                loading={modalValue === 'lost' && updatedCustomerLeadResponse?.loading}
              />
            )}
            {modalValue === 'los' && (
              <>
                <Button color={'warning'} variant={'contained'} title={'No, still archive'} onClick={onSendArchive} />
                <Button color={'success'} variant={'contained'} title={'Yes, send it!'} onClick={onSendEmail} />
              </>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export { RemoveAction };
