import React, { useEffect, useState } from 'react';
import { Button } from '@/components/atoms/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useSettingDialogStyle } from '@/static/stylesheets/molecules';
import {
  clearClientContractPDF,
  clearClientSendContractResponse,
  getClientContractPDF,
  sendClientContract,
} from '@/redux/actions';
import { Box, CircularProgress } from '@mui/material';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';

interface ContractModalProps {
  open?: any;
  leadData?: any;

  onClose?(): void;
}

const ContractModal: React.FC<ContractModalProps> = (props) => {
  const classes = useSettingDialogStyle();
  const { open, onClose, leadData } = props;
  const contractPdfLinkResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_CLIENT_CONTRACT_PDF];
  const contractSendResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.SEND_CLIENT_CONTRACT];
  const [loading, setLoading] = useState(true);
  const [contractPdfFile, setContractPdfFile] = useState<string>();
  const [targetId, setTargetId] = useState<string | null>(null);
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);

  const onSendContract = () => {
    // setButtonLoading(true);
    sendClientContract(leadData?.id);
  };

  const Loader = () => (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <CircularProgress style={{ color: '#ffffff' }} />
    </Box>
  );

  const generateFileLink = () => {
    setLoading(true);
    setTargetId(leadData?.id);
    getClientContractPDF(leadData?.id);
  };

  useEffect(() => {
    if (leadData?.id) {
      generateFileLink();
    }
  }, [leadData]);

  useEffect(() => {
    if (open && targetId === leadData?.id) {
      if (contractPdfLinkResponse && contractPdfLinkResponse?.success) {
        setContractPdfFile(() => contractPdfLinkResponse?.data);
        setLoading(false);
        clearClientContractPDF();
      }

      if (contractPdfLinkResponse && contractPdfLinkResponse?.error) {
        setLoading(false);
        clearClientContractPDF();
      }
    }
  }, [contractPdfLinkResponse, leadData?.id, open, targetId]);

  useEffect(() => {
    if (open && targetId === leadData?.id) {
      if (contractSendResponse && contractSendResponse?.success) {
        toast.success(alertMessages.clients.docusignSendSuccess);
        clearClientSendContractResponse();
      }

      if (contractSendResponse && contractSendResponse?.error) {
        toast.error(alertMessages.clients.contractSendError);
        clearClientSendContractResponse();
      }
    }
  }, [open, contractSendResponse, targetId]);

  return (
    <>
      <Dialog
        className={classes.pdfView}
        open={open}
        onClose={onClose}
        maxWidth={'lg'}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogContent>
          <div>
            {loading ? (
              <Loader />
            ) : (
              <object
                data={contractPdfFile}
                type='application/pdf'
                width='100%'
                height='100%'
                onLoad={() => setIframeLoaded(true)}
              >
                <p>
                  Your browser does not support PDFs.
                  <a href={contractPdfFile}>Download the PDF</a>
                </p>
              </object>
            )}
          </div>
        </DialogContent>
        {loading ? null : (
          <DialogActions>
            <Button
              fullWidth={false}
              onClick={onClose}
              color={'error'}
              variant={'contained'}
              title={'do not send yet'}
            />
            <Button
              fullWidth={false}
              onClick={onSendContract}
              color={'success'}
              variant={'contained'}
              title={'Looks Good, Send to DocuSign'}
              loading={contractSendResponse?.loading}
            />
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export { ContractModal };
