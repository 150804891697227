import React, { useEffect, useState } from 'react';
import { useTaskStyles } from '@/static/stylesheets/molecules/Tasks';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { TextInput } from '@/components/atoms/TextInput';
import { MultiSelect } from '@/components/atoms/MultiSelect';
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { Button } from '@/components/atoms/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useAppSelector, useComponentState, useInput } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { TaskFilterBodyParams, TaskItem, TaskTypeParams, UserParams } from '@/types';
import { clearFilteredTasksResponse, getFilteredTasks, getTasks, getTaskTypes } from '@/redux/actions';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';
import classNames from 'classnames';

interface TasksFilterSidebarProps {}

const TasksFilterSidebar: React.FC<TasksFilterSidebarProps> = (props) => {
  const classes = useTaskStyles();
  const [filterElementsOpen, setFilterElementsOpen] = useState<boolean>(true);

  const toggleFilterElements = () => setFilterElementsOpen(!filterElementsOpen);
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState<boolean>(true);
  const RightDrawer = () => {
    setIsRightDrawerOpen(!isRightDrawerOpen);
  };

  const staffMemberList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_TASK_STAFF_MEMBERS];
  const staffMembers: UserParams[] = staffMemberList?.data?.data ?? [];

  const taskTypeList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_TASK_TYPES];
  const taskTypes: TaskTypeParams[] = taskTypeList?.data?.data ?? [];

  const tasksResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_TASKS];
  const totalTasksCount = tasksResponse?.data?.active_tasks;
  const completedTasksCount = tasksResponse?.data?.completed_tasks;

  const {
    value: assignedTo,
    setValue: setAssignedTo,
    bind: bindAssignedTo,
    reset: resetAssignedTo,
  } = useInput('', {
    onChange(value: any) {},
  });

  const {
    state: {
      selectedPriorities,
      selectedTaskTypes,
      selectedTaskStatus,
      showAllSubTasks,
      showCompletedTasks,
      showRemovedTasks,
    },
    setState,
  } = useComponentState<{
    selectedPriorities: TaskItem['priority'][];
    selectedTaskTypes: string[];
    selectedTaskStatus: string[];
    showAllSubTasks: boolean;
    showCompletedTasks: boolean;
    showRemovedTasks: boolean;
  }>({
    selectedPriorities: [],
    selectedTaskTypes: [],
    selectedTaskStatus: [],
    showAllSubTasks: false,
    showCompletedTasks: false,
    showRemovedTasks: false,
  });

  const clearFilter = () => {
    resetAssignedTo();
    setState({
      selectedPriorities: [],
      selectedTaskTypes: [],
      selectedTaskStatus: [],
      showAllSubTasks: false,
      showCompletedTasks: false,
      showRemovedTasks: false,
    });
    clearFilteredTasksResponse();
    getTaskList();
  };

  const getTaskTypeList = () =>
    getTaskTypes({
      limit: 'all',
      page: 1,
    });

  const getTaskList = () =>
    getTasks({
      limit: 25,
      page: 1,
    });

  const submitFilter = () => {
    if (
      assignedTo === '--empty--' &&
      selectedPriorities?.length === 0 &&
      selectedTaskTypes?.length === 0 &&
      selectedTaskStatus?.length === 0 &&
      !showAllSubTasks &&
      !showCompletedTasks &&
      !showRemovedTasks
    ) {
      toast.error(alertMessages?.tasks?.taskListFilterValidationError);
      return;
    }

    const filterData: TaskFilterBodyParams = {};

    if (showAllSubTasks) {
      filterData.show_child_tasks = showAllSubTasks;
    }

    if (showCompletedTasks) {
      filterData.show_complete_tasks = showCompletedTasks;
    }

    if (showRemovedTasks) {
      filterData.show_deleted_tasks = showRemovedTasks;
    }

    if (assignedTo !== '--empty--') {
      filterData.task_assigned_to = assignedTo?.trim() === '' ? undefined : assignedTo;
    }

    if (selectedTaskTypes?.length > 0) {
      filterData.task_type_id = selectedTaskTypes;
    }

    if (selectedPriorities?.length > 0) {
      filterData.priority_enum = selectedPriorities;
    }

    if (selectedTaskStatus?.length > 0) {
      filterData.task_status_enum = selectedTaskStatus;
    }

    getFilteredTasks(filterData);
  };

  useEffect(() => {
    getTaskTypeList();
  }, []);

  return (
    <div
      className={classNames(classes.tasksFilterSidebarContainer, {
        [classes.tasksFilterSidebarHide]: isRightDrawerOpen,
        [classes.tasksFilterSidebarActive]: !isRightDrawerOpen,
      })}
    >
      <div className={'close_filter_side'}>
        <div onClick={RightDrawer} className={'toggle'}>
          <FilterListIcon />
        </div>
      </div>

      <div className={'filter-header'}>
        {/*<div>Total Tasks: {totalTasksCount}</div>*/}
        <div>Open | Active Tasks: {totalTasksCount}</div>
        <div>Completed Tasks: {completedTasksCount}</div>
      </div>
      <div className={'filter_body_1'}>
        <div className={'filter-accordion'} onClick={toggleFilterElements}>
          <span>Filters</span>
          <span>{!filterElementsOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</span>
        </div>
        {filterElementsOpen && (
          <div className={'filter-elements'}>
            <div style={{ marginTop: assignedTo && assignedTo !== '--empty--' ? '15px' : '0' }}>
              <TextInput
                select={true}
                variant={'standard'}
                options={[
                  {
                    title: 'Assigned To',
                    value: '--empty--',
                    disabled: true,
                  },
                  ...(staffMembers && staffMembers?.length > 0
                    ? staffMembers?.map((item) => ({
                        title: `${item?.first_name ?? ''} ${item?.last_name ?? ''}`,
                        value: item?.id,
                      }))
                    : []),
                ]}
                label={assignedTo && assignedTo !== '--empty--' ? undefined : 'Filter by Assigned To'}
                fullWidth={true}
                {...bindAssignedTo}
              />
            </div>
            <div style={{ marginTop: selectedPriorities?.length > 0 ? '15px' : '0' }}>
              <MultiSelect
                options={[
                  {
                    label: 'No Priority',
                    value: '0',
                  },
                  {
                    label: 'Low Priority',
                    value: '1',
                  },
                  {
                    label: 'Medium Priority',
                    value: '2',
                  },
                  {
                    label: 'High Priority',
                    value: '3',
                  },
                ]}
                value={selectedPriorities}
                onChange={(items) => setState({ selectedPriorities: items })}
                label={selectedPriorities?.length > 0 ? undefined : 'Filter by Priority'}
                multiple={true}
                rootSx={{
                  marginLeft: 0,
                  '& .MuiInputLabel-root': {
                    marginLeft: '-15px',
                  },
                  marginBottom: 0,
                }}
              />
            </div>
            <div style={{ marginTop: selectedTaskTypes?.length > 0 ? '15px' : '0' }}>
              <MultiSelect
                options={[
                  ...(taskTypes && taskTypes?.length > 0
                    ? taskTypes?.map((item) => ({
                        label: item?.task_type_name ?? '',
                        value: item?.id,
                      }))
                    : []),
                ]}
                value={selectedTaskTypes}
                onChange={(items) => setState({ selectedTaskTypes: items })}
                label={selectedTaskTypes?.length > 0 ? undefined : 'Filter by Type of Task'}
                multiple={true}
                rootSx={{
                  marginLeft: 0,
                  '& .MuiInputLabel-root': {
                    marginLeft: '-15px',
                  },
                  marginBottom: 0,
                }}
              />
            </div>
            <div style={{ marginTop: selectedTaskStatus?.length > 0 ? '15px' : '0' }}>
              <MultiSelect
                options={[
                  {
                    label: 'On Track',
                    value: '0',
                  },
                  {
                    label: 'Need Attention',
                    value: '1',
                  },
                  {
                    label: 'Help Needed',
                    value: '2',
                  },
                ]}
                value={selectedTaskStatus}
                onChange={(items) => setState({ selectedTaskStatus: items })}
                label={selectedTaskStatus?.length > 0 ? undefined : 'Filter by Task Status'}
                multiple={true}
                rootSx={{
                  marginLeft: 0,
                  '& .MuiInputLabel-root': {
                    marginLeft: '-15px',
                  },
                  marginBottom: 0,
                }}
              />
            </div>
            <div>
              <FormGroup
                sx={{
                  marginTop: '15px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAllSubTasks}
                      onClick={() => setState({ showAllSubTasks: !showAllSubTasks })}
                    />
                  }
                  label={'Show All Subtasks'}
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup
                sx={{
                  marginTop: '15px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showCompletedTasks}
                      onClick={() => setState({ showCompletedTasks: !showCompletedTasks })}
                    />
                  }
                  label={'Show Completed Tasks'}
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup
                sx={{
                  marginTop: '15px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showRemovedTasks}
                      onClick={() => setState({ showRemovedTasks: !showRemovedTasks })}
                    />
                  }
                  label={'Show Removed Tasks'}
                />
              </FormGroup>
            </div>
            <div style={{ marginTop: 15 }}>
              <Grid container spacing={1}>
                <Grid item lg={6}>
                  <Button
                    title={'Clear Filters'}
                    color={'error'}
                    size={'small'}
                    fullWidth={true}
                    onClick={clearFilter}
                  />
                </Grid>
                <Grid item lg={6}>
                  <Button
                    title={'Apply Filters'}
                    color={'primary'}
                    size={'small'}
                    fullWidth={true}
                    onClick={submitFilter}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { TasksFilterSidebar };
