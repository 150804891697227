import { Button } from '@/components/atoms/Button';
import { alertMessages } from '@/config';
import { useAppSelector } from '@/hooks';
import {
  clearLeadContractPDF,
  clearLeadSendContractResponse,
  getLeadContractPDF,
  sendLeadContract,
} from '@/redux/actions';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { useSettingDialogStyle } from '@/static/stylesheets/molecules';
import { Box, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

interface ContractModalProps {
  open?: any;
  leadData?: any;

  onClose?(): void;
}

const ContractModal: React.FC<ContractModalProps> = (props) => {
  const classes = useSettingDialogStyle();
  const { open, onClose, leadData } = props;
  const contractPdfLinkResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_LEAD_CONTRACT_PDF];
  const contractSendResponse = useAppSelector(selectHttpState)[CORRELATION_IDS.SEND_LEAD_CONTRACT];
  const [loading, setLoading] = useState<boolean>(false);
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);
  const [contractPdfFile, setContractPdfFile] = useState<string>();
  const [targetId, setTargetId] = useState<string | null>(null);

  const onSendContract = () => {
    sendLeadContract(leadData?.id);
  };

  const Loader = () => (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <CircularProgress style={{ color: '#ffffff' }} />
    </Box>
  );

  const generateFileLink = () => {
    setLoading(true);
    setTargetId(leadData?.id);
    getLeadContractPDF(leadData?.id);
  };

  useEffect(() => {
    if (leadData?.id) {
      generateFileLink();
    }
  }, [leadData]);

  useEffect(() => {
    if (open && targetId === leadData?.id) {
      if (contractPdfLinkResponse && contractPdfLinkResponse?.success) {
        setContractPdfFile(contractPdfLinkResponse?.data);
        setLoading(false);
        clearLeadContractPDF();
      }

      if (contractPdfLinkResponse && contractPdfLinkResponse?.error) {
        setLoading(false);
        clearLeadContractPDF();
      }
    }
  }, [contractPdfLinkResponse, leadData?.id, open, targetId]);

  useEffect(() => {
    if (open && targetId === leadData?.id) {
      if (contractSendResponse && contractSendResponse?.success) {
        toast.success(alertMessages.leads.docusignSendSuccess);
        clearLeadSendContractResponse();
      }

      if (contractSendResponse && contractSendResponse?.error) {
        toast.error(alertMessages.leads.docusignSendError);
        clearLeadSendContractResponse();
      }
    }
  }, [open, contractSendResponse, targetId]);

  return (
    <div className='relative'>
      <Dialog
        className={classes.pdfView}
        open={open}
        onClose={onClose}
        maxWidth={'lg'}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogContent>
          <div>
            {loading ? (
              <Loader />
            ) : (
              <object
                data={contractPdfFile}
                type='application/pdf'
                width='100%'
                height='100%'
                onLoad={() => setIframeLoaded(true)}
              >
                <p>
                  Your browser does not support PDFs.
                  <a href={contractPdfFile}>Download the PDF</a>
                </p>
              </object>
            )}
          </div>
        </DialogContent>
        {loading ? null : (
          <DialogActions>
            <Button
              fullWidth={false}
              onClick={onClose}
              color={'error'}
              variant={'contained'}
              title={'do not send yet'}
            />
            <Button
              fullWidth={false}
              onClick={onSendContract}
              color={'success'}
              variant={'contained'}
              title={'Looks Good, Send to DocuSign'}
              loading={contractSendResponse?.loading}
              disabled={loading || contractSendResponse?.loading}
            />
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export { ContractModal };
