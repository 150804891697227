import React, { useEffect, useState } from 'react';
import { Button } from '@/components/atoms/Button';
import { useEmailTemplateStyles } from '@/static/stylesheets/molecules';
import { Typography } from '@mui/material';
import { DataTable } from '@/components/organisms/DataTable';
import { ActionButton } from '@/components/atoms/Button';
import { CustomVariablesForm } from '@/components/molecules/Settings/Email/CustomVariables';
import {
  clearDeletedCustomVariable,
  deleteCustomVariable,
  getCustomVariables,
  openConfirmationDialog,
} from '@/redux/actions';
import { useAppSelector } from '@/hooks';
import { CORRELATION_IDS, selectHttpState } from '@/redux/reducers/http';
import { CustomVariableParams } from '@/types';
import toast from 'react-hot-toast';
import { alertMessages } from '@/config';

interface CustomVariablesProps {}

const CustomVariables: React.FC<CustomVariablesProps> = () => {
  const classes = useEmailTemplateStyles();
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const customVariableList = useAppSelector(selectHttpState)[CORRELATION_IDS.GET_CUSTOM_VARIABLES];
  const deletedCustomVariable = useAppSelector(selectHttpState)[CORRELATION_IDS.DELETE_CUSTOM_VARIABLE];
  const customVariables = customVariableList?.data?.data ?? [];
  const totalPages = customVariableList?.data?.last_page ?? 0;

  const [editFormData, setEditFormData] = useState<CustomVariableParams | null>(null);

  const [limit, setLimit] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchCustomVariables = () =>
    getCustomVariables({
      limit,
      page: currentPage,
    });

  const deleteVariable = (id: CustomVariableParams['customVariable_id']) => {
    deleteCustomVariable(id);
  };

  const openFormDialog = (mode: 'add' | 'edit', formData: CustomVariableParams | null) => {
    setFormDialogOpen(true);
    setEditFormData(formData);
  };

  const handleDialogClose = () => {
    setFormDialogOpen(false);
  };

  const formatColumnsToString = (data: any) => {
    const parsedJson = data ? JSON.parse(data) : [];
    let string = '';

    if (parsedJson && parsedJson?.length > 0) {
      for (let i = 0; i < parsedJson?.length; i++) {
        if (i > 0) {
          string += ' + ';
        }
        string += `'${parsedJson[i]}'`;
      }
    }

    return string;
  };

  useEffect(() => {
    if (deletedCustomVariable?.success) {
      toast.success(alertMessages.generalSettings.customVariables.deleteSuccess);
      fetchCustomVariables();
      clearDeletedCustomVariable();
    }

    if (deletedCustomVariable?.error) {
      toast.error(alertMessages.generalSettings.customVariables.deleteError);
      clearDeletedCustomVariable();
    }
  }, [deletedCustomVariable]);

  useEffect(() => {
    fetchCustomVariables();
  }, [currentPage]);
  return (
    <>
      <div className={classes.cardHeader} style={{ margin: '0px 20px' }}>
        <Typography variant='subtitle1' component='div'>
          Custom Variables
        </Typography>
        <div>
          <Button onClick={() => openFormDialog('add', null)} title={'Add Variable'} size={'small'} />
        </div>
      </div>
      <div style={{ textAlign: 'center', padding: 20 }}>
        <div style={{ width: '100%', display: 'inline-block' }}>
          <DataTable
            heads={[
              { label: 'Variable', align: 'left' },
              { label: 'Linked To', align: 'left' },
              { label: 'Actions', align: 'center' },
            ]}
            rows={[
              ...customVariables?.map((variable: CustomVariableParams) => [
                { label: variable?.name, align: 'left' },
                { label: formatColumnsToString(variable?.column), align: 'left' },
                {
                  label: (
                    <div className={'action-icon'}>
                      <ActionButton onClick={() => openFormDialog('edit', variable)} icon={'edit'} />
                      <ActionButton
                        icon={'delete'}
                        onClick={() =>
                          openConfirmationDialog({
                            title: 'Are you sure?',
                            message: alertMessages.generalSettings.customVariables.deleteConfirm,
                            type: 'error',
                            textCenter: 'center',
                            onClickConfirm() {
                              deleteVariable(variable?.id);
                            },
                          })
                        }
                      />
                    </div>
                  ),
                  align: 'center',
                },
              ]),
            ]}
            hasPagination={true}
            paginationProps={{
              count: totalPages,
              currentPage,
              onChange(value) {
                setCurrentPage(value);
              },
            }}
          />
        </div>
        <CustomVariablesForm
          openDialog={formDialogOpen}
          onClose={handleDialogClose}
          formData={editFormData}
          onSuccess={fetchCustomVariables}
        />
      </div>
    </>
  );
};

export { CustomVariables };
